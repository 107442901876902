import ProgramRateActionTypes from '../../Actions/ProgramRate/ActionTypes';

export const programRateReducer = (state = [], action) => {
    switch (action.type) {
        // case ProgramRateActionTypes.FETCH_ALLPROGRAMRATES:
        //     return action.payload;

        // case ProgramRateActionTypes.FETCH_PROGRAMRATE:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // // return [...state, action.payload];

        case ProgramRateActionTypes.FETCH_PROGRAMRATEFROMDATA:
            return action.payload;

        // case ProgramRateActionTypes.ADD_PROGRAMRATE:
        //     return [...state, action.payload];

        // case ProgramRateActionTypes.EDIT_PROGRAMRATE:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];


        // case ProgramRateActionTypes.DELETE_PROGRAMRATE:
        //     return [...state.filter(item => item.id !== action.payload.id)];

        // case ProgramRateActionTypes.COPY_PROGRAMRATE:
        //     return [...state, action.payload];

        case ProgramRateActionTypes.PUBLISH_PROGRAM_COMMENT:
            return [...state.map(item => item.id === action.payload.id ? action.payload : item)];

        case ProgramRateActionTypes.UNPUBLISH_PROGRAM_COMMENT:
            return [...state.map(item => item.id === action.payload.id ? action.payload : item)];

        default:
            return state;
    }
}