// export const LOADING = 'LOADING';

const OfferOptionActionTypes = {};

OfferOptionActionTypes.FETCH_ALL_PROGRAMSOPTION = 'FETCH_ALL_PROGRAMSOPTION';
OfferOptionActionTypes.FETCH_PROGRAMOPTION = 'FETCH_PROGRAMOPTION';
OfferOptionActionTypes.FETCH_PROGRAMOPTIONFROMDATA = 'FETCH_PROGRAMOPTIONFROMDATA';
OfferOptionActionTypes.ADD_PROGRAMOPTION = 'ADD_PROGRAMOPTION';
OfferOptionActionTypes.EDIT_PROGRAMOPTION = 'EDIT_PROGRAMOPTION';
OfferOptionActionTypes.DELETE_PROGRAMOPTION = 'DELETE_PROGRAMOPTION';
OfferOptionActionTypes.TRUNCATE_PROGRAMOPTION = 'TRUNCATE_PROGRAMOPTION';

export default OfferOptionActionTypes;
