import ReservationOptionActionTypes from '../../Actions/ReservationOption/ActionTypes';

export const ReservationOptionReducer = (state = [], action) => {
    switch (action.type) {
        case ReservationOptionActionTypes.FETCH_ALLRESERVATIONOPTION:
            return action.payload;

        case ReservationOptionActionTypes.FETCH_RESERVATIONOPTION:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];
    
        case ReservationOptionActionTypes.FETCH_RESERVATIONOPTIONFROMDATA:
            return action.payload;

        case ReservationOptionActionTypes.ADD_RESERVATIONOPTION:
            return [...state, action.payload];

        case ReservationOptionActionTypes.EDIT_RESERVATIONOPTION:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ReservationOptionActionTypes.DELETE_RESERVATIONOPTION:
            return [...state.filter(item => item !== action.payload)];

        // case ReservationOptionActionTypes.SEARCH_RESERVATIONOPTION:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];

        default:
            return state;
    }
}