import LanguagesActionTypes from '../../Actions/Languages/ActionTypes';

export const languagesReducer = (state = [], action) => {
    switch (action.type) {
        case LanguagesActionTypes.FETCH_LANGUAGES:
            return action.payload;
            
        case LanguagesActionTypes.FETCH_LANGUAGE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload] 
            // return [...state, action.payload];
        
        case LanguagesActionTypes.ADD_LANGUAGE:
            return [...state, action.payload];

        case LanguagesActionTypes.EDIT_LANGUAGE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case LanguagesActionTypes.DELETE_LANGUAGE:
            return [...state.filter(item => item !== action.payload)];
            
        default:
            return state;
    }
}