import TestimonialsActionTypes from '../../Actions/Testimonials/ActionTypes';

export const testimonialsReducer = (state = [], action) => {
    switch (action.type) {
        case TestimonialsActionTypes.FETCH_ALL_TESTIMONIALS:
            return action.payload;

        case TestimonialsActionTypes.FETCH_TESTIMONIALS:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case TestimonialsActionTypes.ADD_TESTIMONIALS:
            return [...state, action.payload];

        case TestimonialsActionTypes.EDIT_TESTIMONIALS:
            return state.map(item => item.id === action.payload.id ? action.payload : item);

        case TestimonialsActionTypes.DELETE_TESTIMONIALS:
            return state.filter(item => item.id !== action.payload.id);

        default:
            return state;
    }
}