// export const LOADING = 'LOADING';

const ServicePriceActionTypes = {};

ServicePriceActionTypes.FETCH_ALLSERVICESPRICE = 'FETCH_ALLSERVICESPRICE';
ServicePriceActionTypes.FETCH_SERVICEPRICE = 'FETCH_SERVICEPRICE';
ServicePriceActionTypes.FETCH_SERVICEPRICEFROMDATA = 'FETCH_SERVICEPRICEFROMDATA';
ServicePriceActionTypes.ADD_SERVICEPRICE = 'ADD_SERVICEPRICE';
ServicePriceActionTypes.EDIT_SERVICEPRICE = 'EDIT_SERVICEPRICE';
ServicePriceActionTypes.DELETE_SERVICEPRICE = 'DELETE_SERVICEPRICE';
ServicePriceActionTypes.TRUNCATE_SERVICEPRICE = 'TRUNCATE_SERVICEPRICE';

export default ServicePriceActionTypes;
