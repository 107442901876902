import UserActionTypes from '../../Actions/User/ActionTypes';

export const UserReducer = (state = { list: [], currentUser: {}, currentUserPerm: [] || "", notMapped: [] }, action) => {
    switch (action.type) {
        case UserActionTypes.FETCH_USERS:
            return { ...state, list: action.payload };

        case UserActionTypes.FETCH_USER:
            return { ...state, list: [...state.list.filter(item => item.id !== action.payload.id), action.payload] };
        // return [...state, action.payload];

        case UserActionTypes.ADD_USER:
            return { ...state, list: [...state.list, action.payload] };

        case UserActionTypes.EDIT_USER:
            return { ...state, list: [...state.list.filter(item => item.id !== action.payload.id), {...state.list.filter(item => item.id === action.payload.id)[0], ...action.payload} ] };

        case UserActionTypes.DELETE_USER:
            return { ...state, list: [...state.list.filter(item => item !== action.payload)] };

        case UserActionTypes.SET_STATUS:
            return { ...state, list: [...state.list.map(item => item.id === action.payload.id ? { ...item, isActive: action.payload.isActive } : item)] };

        case UserActionTypes.GET_CURRENTUSER:
            return { ...state, currentUser: action.payload };

        case UserActionTypes.SET_CURRENTUSER_PERM:
            return { ...state, currentUserPerm: action.payload };

        case UserActionTypes.GET_NOTMAPPEDPROVIDERS:
            return { ...state, notMapped: action.payload };

        default:
            return state;
    }
}