// export const LOADING = 'LOADING';

const ProgramRateActionTypes = {};

// ProgramRateActionTypes.FETCH_ALLPROGRAMRATES = 'FETCH_ALLPROGRAMRATES';
// ProgramRateActionTypes.FETCH_PROGRAMRATE = 'FETCH_PROGRAMRATE';
// ProgramRateActionTypes.FETCH_PROGRAMRATEBYPROGRAMID = 'FETCH_PROGRAMRATEBYPROGRAMID';
ProgramRateActionTypes.FETCH_PROGRAMRATEFROMDATA = 'FETCH_PROGRAMRATEFROMDATA';
// ProgramRateActionTypes.ADD_PROGRAMRATE = 'ADD_PROGRAMRATE';
// ProgramRateActionTypes.EDIT_PROGRAMRATE = 'EDIT_PROGRAMRATE';
// ProgramRateActionTypes.DELETE_PROGRAMRATE = 'DELETE_PROGRAMRATE';
// ProgramRateActionTypes.COPY_PROGRAMRATE = 'COPY_PROGRAMRATE';
ProgramRateActionTypes.PUBLISH_PROGRAM_COMMENT = 'PUBLISH_PROGRAM_COMMENT';
ProgramRateActionTypes.UNPUBLISH_PROGRAM_COMMENT = 'UNPUBLISH_PROGRAM_COMMENT';

export default ProgramRateActionTypes;
