import React, { createContext, useState, useEffect/*, lazy, memo*/ } from 'react';
// import Api, { axe } from "./shared/Api";
import { fetchPermissionByRole } from "../redux/Actions/Permission/PermissionActions";
import { useDispatch, useSelector } from 'react-redux';
// import { Ability } from '@casl/ability';
import AllPermissions from './allPermissions';
import { setCurrentUserPerm } from "../redux/Actions/User/UserActions";

// const fs = require('fs');
// const path = require('path');

// const modules = {
//     Cities: lazy(() => import('./views/Cities/Cities')),
//     Types: lazy(() => import('./views/Types/Types')),
//     Category: lazy(() => import('./views/Category/Category')),
//     Provider: lazy(() => import('./views/Provider/Provider')),
//     ProviderDetails: lazy(() => import('./views/Provider/ProviderDetails/ProviderDetails')),
//     Location: lazy(() => import('./views/Location/Location')),
//     LocationDetails: lazy(() => import('./views/Location/LocationDetails/LocationDetails')),
//     Service: lazy(() => import('./views/Service/Service')),
//     ServiceDetails: lazy(() => import('./views/Service/ServiceDetails/ServiceDetails')),
//     Reservation: lazy(() => import('./views/Reservation/Reservation')),
//     ReservationDetails: lazy(() => import('./views/Reservation/ReservationDetails/ReservationDetails')),
//     User: lazy(() => import('./views/User/User')),
//     UserDetails: lazy(() => import('./views/User/UserDetails/UserDetails')),
//     Languages: lazy(() => import('./views/Languages/Languages')),
//     Resources: lazy(() => import('./views/Resources/Resources')),
//     Audit: lazy(() => import('./views/Audit/Audit')),
//     UserRole: lazy(() => import('./views/UserRole/UserRole')),

//     ProviderDashServices: lazy(() => import('./views/ProviderDash/Service/ProviderDashService')),
//     ProviderDashServiceDetails: lazy(() => import('./views/ProviderDash/Service/ServiceDetails/ProviderDashServiceDetails')),
//     ProviderDashReservation: lazy(() => import('./views/ProviderDash/Reservation/ProviderDashReservation')),
//     ProviderDashReservationDetails: lazy(() => import('./views/ProviderDash/Reservation/ReservationDetails/ProviderDashReservationDetails')),
//     ProviderDashVacation: lazy(() => import('./views/ProviderDash/Vacations/ProviderDashVacation')),
//     ProviderDashDocuments: lazy(() => import('./views/ProviderDash/Documents'))
// };

export const PermissionsContext = createContext();

const UserPermissionsProvider = props => {

    let users = useSelector(state => state.users);
    let dispatch = useDispatch();
    let lstrg = sessionStorage.getItem('userStatus');
    let userStatus = lstrg && JSON.parse(lstrg);
    // let ability = new Ability();

    const [userPerms, setUserPerms] = useState((userStatus && userStatus.perms && atob(userStatus.perms).split(',')) || AllPermissions.filter(perm => perm.search(/^(reservation).*?(edit|get)|^(vacation).*?(delete|get)|^(document).*?(delete|get)/gi) > -1) || []);
    // const [userAbility, setUserAbility] = useState(ability);
    // const [currentModule, setCurrentModule] = useState({});
    const [permsLoading, setPermsLoading] = useState(false);

    const getPermissionByRole = async () => {
        setPermsLoading(true);
        let disp = async () => dispatch(fetchPermissionByRole(userStatus && atob(userStatus.role).split('_')[1]));
        let getF = await disp();
        if (getF) {
            setUserPerms(getF.payload);
            userStatus.perms = btoa(getF.payload);
            sessionStorage.setItem('userStatus', JSON.stringify(userStatus));
            let fet = async () => dispatch(setCurrentUserPerm(userStatus.perms));
            await fet();
        }
        setPermsLoading(false);
    };

    useEffect(() => {
        try {
            if (userStatus && !userStatus.perms) getPermissionByRole();
        } catch (error) {
            console.log(error);
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let sstrg = sessionStorage.getItem('userStatus');
        let userState = sstrg && JSON.parse(sstrg);
        let tout = 0;
        try {
            if (userState && userState.perms) {
                setPermsLoading(true);
                tout = setTimeout(() => {
                    setUserPerms(atob(userStatus.perms).split(',') || userPerms);
                    setPermsLoading(false);
                    clearTimeout(tout);
                }, 1500);
            }
            // getPermissionByRole();
        } catch (error) {
            console.log(error);
        }

        return () => clearTimeout(tout);

        // eslint-disable-next-line
    }, [users.currentUserPerm]);


    // useEffect(() => {
    //     // let { rules: userPerm } = ability;
    //     setUserAbility(new Ability(
    //         Object.keys(modules).map(module => {
    //             return {
    //                 action: userPerms,
    //                 subject: module,
    //                 // conditions: { published: true },
    //                 inverted: [...new Set(AllPermissions.filter(perm => userPerm.includes(perm)))].length <= 0 && currentModule === module
    //             }
    //         })
    //     ))
    // }, [userPerm, currentModule]);


    return (
        <PermissionsContext.Provider value={{ userPerms: [...userPerms], AllPermissions: AllPermissions, permsLoading/*, setCurrentModule*/ }}>
            {props.children}
        </PermissionsContext.Provider>
    )
};

export default UserPermissionsProvider;
