import ProviderActionTypes from '../../Actions/Provider/ActionTypes';

export const ProviderReducer = (state = [], action) => {
    switch (action.type) {
        case ProviderActionTypes.FETCH_PROVIDERS:
            return action.payload;

        case ProviderActionTypes.FETCH_PROVIDER:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ProviderActionTypes.ADD_PROVIDER:
            return [...state, action.payload];

        case ProviderActionTypes.EDIT_PROVIDER:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProviderActionTypes.ENABLE_PROVIDER:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProviderActionTypes.DISABLE_PROVIDER:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProviderActionTypes.DELETE_PROVIDER:
            return [...state.filter(item => item !== action.payload)];

        default:
            return state;
    }
}