// export const LOADING = 'LOADING';

const ServiceAvailabilityActionTypes = {};

ServiceAvailabilityActionTypes.FETCH_ALLSERVICESAVAILABILITY = 'FETCH_ALLSERVICESAVAILABILITY';
ServiceAvailabilityActionTypes.FETCH_SERVICEAVAILABILITY = 'FETCH_SERVICEAVAILABILITY';
ServiceAvailabilityActionTypes.FETCH_SERVICEAVAILABILITYFROMDATA = 'FETCH_SERVICEAVAILABILITYFROMDATA';
ServiceAvailabilityActionTypes.ADD_SERVICEAVAILABILITY = 'ADD_SERVICEAVAILABILITY';
ServiceAvailabilityActionTypes.EDIT_SERVICEAVAILABILITY = 'EDIT_SERVICEAVAILABILITY';
ServiceAvailabilityActionTypes.DELETE_SERVICEAVAILABILITY = 'DELETE_SERVICEAVAILABILITY';

export default ServiceAvailabilityActionTypes;
