import ProviderDocumentActionTypes from '../../Actions/ProviderDocument/ActionTypes';

export const providerDocumentReducer = (state = [], action) => {
    switch (action.type) {
        case ProviderDocumentActionTypes.FETCH_PROVIDER_DOCUMENTS:
            return action.payload;

        case ProviderDocumentActionTypes.FETCH_PROVIDER_DOCUMENT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload]
        // return [...state, action.payload];

        case ProviderDocumentActionTypes.ADD_PROVIDER_DOCUMENT:
            return [...state, action.payload];

        case ProviderDocumentActionTypes.EDIT_PROVIDER_DOCUMENT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProviderDocumentActionTypes.DELETE_PROVIDER_DOCUMENT:
            return [...state.filter(item => item !== action.payload)];

        case ProviderDocumentActionTypes.FETCH_PROVIDER_DOCUMENT_BY_ID:
            return action.payload;

        default:
            return state;
    }
}