import ServicePriceActionTypes from '../../Actions/ServicePrice/ActionTypes';

export const ServicePriceReducer = (state = [], action) => {
    switch (action.type) {
        case ServicePriceActionTypes.FETCH_ALLSERVICESPRICE:
            return action.payload;

        case ServicePriceActionTypes.FETCH_SERVICEPRICE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ServicePriceActionTypes.FETCH_SERVICEPRICEFROMDATA:
            return action.payload;

        case ServicePriceActionTypes.ADD_SERVICEPRICE:
            return [...state, action.payload];

        case ServicePriceActionTypes.EDIT_SERVICEPRICE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ServicePriceActionTypes.DELETE_SERVICEPRICE:
            return [...state.filter(item => item.id !== action.payload.id)];

        case ServicePriceActionTypes.TRUNCATE_SERVICEPRICE:
            return action.payload.reducerDataTypeSymbol;

        default:
            return state;
    }
}