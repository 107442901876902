// export const LOADING = 'LOADING';

const LocationActionTypes = {};

LocationActionTypes.FETCH_LOCATIONS = 'FETCH_LOCATIONS';
LocationActionTypes.FETCH_LOCATION = 'FETCH_LOCATION';
LocationActionTypes.ADD_LOCATION = 'ADD_LOCATION';
LocationActionTypes.EDIT_LOCATION = 'EDIT_LOCATION';
LocationActionTypes.DELETE_LOCATION = 'DELETE_LOCATION';
LocationActionTypes.ENABLE_LOCATION = 'ENABLE_LOCATION';
LocationActionTypes.DISABLE_LOCATION = 'DISABLE_LOCATION';
// LocationActionTypes.SEARCH_LOCATION = 'SEARCH_LOCATION';


export default LocationActionTypes;
