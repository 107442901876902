import PaymentActionTypes from '../../Actions/Payment/ActionTypes';

export const PaymentReducer = (state = [], action) => {
    switch (action.type) {
        case PaymentActionTypes.FETCH_ALLPAYMENT:
            return action.payload;

        case PaymentActionTypes.FETCH_PAYMENT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case PaymentActionTypes.FETCH_PAYMENTFROMDATA:
            return action.payload;

        case PaymentActionTypes.ADD_PAYMENT:
            return [...state, action.payload];

        case PaymentActionTypes.EDIT_PAYMENT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case PaymentActionTypes.DELETE_PAYMENT:
            return [...state.filter(item => item !== action.payload)];

        // case PaymentActionTypes.SEARCH_PAYMENT:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];

        default:
            return state;
    }
}