import ContactActionTypes from '../../Actions/Contact/ActionTypes';

export const contactReducer = (state = [], action) => {
    switch (action.type) {
        case ContactActionTypes.FETCH_ALL_CONTACT:
            return action.payload;
            
        case ContactActionTypes.FETCH_CONTACT:
            return state.map(item => item.id === action.payload.id ? action.payload : item); 
        
        case ContactActionTypes.ADD_CONTACT:
            return [...state, action.payload];

        case ContactActionTypes.EDIT_CONTACT:
            return state.map(item => item.id === action.payload.id ? action.payload : item);

        case ContactActionTypes.DELETE_CONTACT:
            return state.filter(item => item.id !== action.payload.id);
            
        default:
            return state;
    }
}