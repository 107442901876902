import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch/*, Redirect*/ } from 'react-router-dom';

import Skeleton from "antd/es/skeleton";

import './App.scss';

import IntlContextProvider, { I18n } from "./intl/IntlProvider";
import UserPermissionsProvider from "./permissions/UserPermissionsProvider";

// const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));


const App = (props) => {
  return (
    <IntlContextProvider>
      <AppRoutes />
    </IntlContextProvider>
  )
};

const AppRoutes = props => {

  const { langLoading } = useContext(I18n);

  return (
    <Router>
      <React.Suspense fallback={<div style={{ padding: 15 }}><Skeleton title={false} paragraph={{ rows: 15 }} active /></div>}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Skeleton loading={langLoading} title={false} paragraph={{ rows: 15 }} active><Login {...props} /></Skeleton>} />
          <Route exact path="/register" name="Register Page" render={props => <Skeleton loading={langLoading} title={false} paragraph={{ rows: 15 }} active><Register {...props} /></Skeleton>} />
          <Route exact path="/404" name="Page 404" render={props => <Skeleton loading={langLoading} title={false} paragraph={{ rows: 15 }} active><Page404 {...props} /></Skeleton>} />
          <Route exact path="/500" name="Page 500" render={props => <Skeleton loading={langLoading} title={false} paragraph={{ rows: 15 }} active><Page500 {...props} /></Skeleton>} />
          <Route path="/" name="Home" render={props => <UserPermissionsProvider><DefaultLayout /*setdir={setDir}*/ {...props} /></UserPermissionsProvider>} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
