// import React from "react";
// import { SettingsContext } from "../AppSettingsProvider";
import axios from 'axios';

// let settings = null;
// if (process.env.NODE_ENV === "development") {
//     settings = require('../react.settings.json');
// }
// else if (process.env.NODE_ENV === "production") {
//     settings = require('../react.settings.json');
// }

// const { REACT_APP_BASEURL } = settings;


export const CancelToken = axios.CancelToken;
// export const CnclTokenSource = CancelToken.source();

export let setSession = () => {
    // sessionStorage.setItem("pr", "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxIiwiVXNlck5hbWUiOiJsYWJhZG1pbkBrZXlmcmFtZS1lZy5jb20iLCJFbWFpbCI6IiIsIlBob25lIjoiIiwiZXhwIjo3MjU5OTAwODMyLjAsIm5iZiI6NzI5MTUwODIzMi4wLCJpYXQiOjcyNTk4ODU4MzIuMH0.YExXvkjQkF60TBssd_TuUVbfcbF868MpGvJeHtorYRz9NXwGUzY1ClpSGpgYMSAiiZHMwRHX0gVEyyTkLrQZ9Q");
    // sessionStorage.setItem("pr", "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxIiwiVXNlck5hbWUiOiJ0ZXN0IiwiRW1haWwiOiIiLCJQaG9uZSI6IiIsImV4cCI6NzI1NjI2NDc4MC4wLCJuYmYiOjcyODc4NzIxODAuMCwiaWF0Ijo3MjU2MjQ5NzgwLjB9.5PiT9HCVTC0vXmhKjm8KHElqgnKpnamu0wuTlpICT46vsk0j-tgEEZc3b-pfOozlhlRR-VtNQtkLAEBBS37vNg");
    sessionStorage.setItem("pr", "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxMDE2IiwiVXNlck5hbWUiOiJVc2VyX0FkbWluIiwiRW1haWwiOiIiLCJQaG9uZSI6IiIsImV4cCI6NzI2NDgzNjYxNy4wLCJuYmYiOjcyOTYzNTc2MTcuMCwiaWF0Ijo3MjY0ODIxNjE3LjB9.gf8ydut5elZGu76ueXAe_5gwY4_HcSnmN0wWZ1xfFaOhEUXXQhc52TTu2fYIqeyFrJnH2JvhN_wjLX98Pbe51A");
}
// setSession();

// const par = sessionStorage.getItem('pr');
const lstrg = sessionStorage.getItem('userStatus');
const par = lstrg ? JSON.parse(lstrg).par : '';
//export const ConferanceId = 'kI7QaBEQZRhbQ7AEAxNLpw==';

export default axios.create({
    //live: https://confliveapi.key-frame.cf/api/   test: http://conference.key-frame.cf/api/  `http://conference.key-frame.cf/api/`
    // baseURL: `https://jsonplaceholder.typicode.com/`,
    baseURL: process.env.REACT_APP_BASEURL || `https://mqased.key-frame.cf/api/`,
    headers: { 'Authorization': par ? "Bearer " + par : '' },
    // cancelToken: CnclTokenSource.token,
    // transformResponse: (data) => {
    //     // Do whatever you want to transform the data
    //     let d = data && JSON.parse(data);
    //     if (!d.isAuth) sessionStorage.removeItem('userStatus');
    //     return data;
    // },
});

//========================================
export const axe = axios;

const axiosConfig = (par, params = {}) => {
    return {
        baseURL: process.env.REACT_APP_BASEURL || `http://mqased.key-frame.cf/api/`,
        headers: { 'Authorization': par ? "Bearer " + par : '' },
        params,
        // cancelToken: CnclTokenSource.token
    }
};

export const getRequest = async ({ target = '', params = {} }) => {
    if (!target) return console.log("No ajax Target url");

    let Par = sessionStorage.getItem('pr');

    try {
        let req = await axios.get(target, axiosConfig(Par, params));

        if (req.data.isDone) return req.data;

    } catch (error) {
        console.log('Error: ', error);
    }
};

export const postRequest = async ({ target = '', data = {} }) => {
    if (!target && !data) return console.log("No ajax Target url && data");

    let Par = sessionStorage.getItem('pr');

    try {
        let req = await axios.post(target, data, axiosConfig(Par));

        if (req.data.isDone) return req.data;

    } catch (error) {
        console.log('Error: ', error);
    }
};