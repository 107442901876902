import CityActionTypes from '../../Actions/Cities/ActionTypes';

export const citiesReducer = (state = [], action) => {
    switch (action.type) {
        case CityActionTypes.FETCH_CITIES:
            return action.payload;

        case CityActionTypes.FETCH_CITY:
            return [...state.filter(item => item.id !== action.payload.id), action.payload]
        // return [...state, action.payload];

        case CityActionTypes.ADD_CITY:
            return [...state, action.payload];

        case CityActionTypes.EDIT_CITY:
            return state.map(item => item.id !== action.payload.id ? item : action.payload);

        case CityActionTypes.ENABLE_CITY:
            return state.map(item => item.id !== action.payload.id ? item : action.payload);

        case CityActionTypes.DISABLE_CITY:
            return state.map(item => item.id !== action.payload.id ? item : action.payload);

        case CityActionTypes.DELETE_CITY:
            return [...state.filter(item => item !== action.payload)];

        default:
            return state;
    }
}