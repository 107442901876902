import React, { createContext, useState, useEffect } from 'react';

import { IntlProvider, createIntl, createIntlCache } from 'react-intl';

import ConfigProvider from 'antd/es/config-provider';
import ar_EG from 'antd/es/locale/ar_EG';
import en_US from 'antd/es/locale/en_US';
import locale_ar from "./locales/ar_EG";
import locale_en from "./locales/en_US";

import moment from 'moment';
import { ar } from 'moment/locale/ar';
import { en } from 'moment/locale/en-ca';

// import Skeleton from "antd/es/skeleton";

// import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css';
// import '../shared/scss/antd-rtl.scss';
// import { locale as coreJsLocale } from 'core-js';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'antd/dist/antd.min.css';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

let initConfig = { locale: 'en', messages: Object.assign({}, en_US, locale_en) };
// to provide it to outside of react env lifecycle
export let intl = createIntl(initConfig, cache);

export const I18n = createContext();

const defaultLocale = navigator.language || "en-US";

const IntlContextProvider = props => {

    const ar_locale = Object.assign({}, ar_EG, locale_ar);
    const en_locale = Object.assign({}, en_US, locale_en);

    let initialState = {
        lang: defaultLocale === "ar-EG" ? 'ar' : defaultLocale.split('-')[0] || 'en',
        locale: defaultLocale === "ar-EG" ? ar_locale : en_locale,
        dir: defaultLocale === "ar-EG" ? 'rtl' : 'ltr'
    };

    // eslint-disable-next-line
    const [locale, setLocale] = useState(initialState);
    const [loading, setLoading] = useState(true);
    // const [cssLoaded, setCssLoaded] = useState(true);
    // intl.locale = initialState.lang;
    // intl.messages = initialState.locale;

    const lstrg = localStorage.getItem("app.Intl_lang");

    useEffect(() => {
        let strg = lstrg && JSON.parse(lstrg);
        if (strg) {
            setLocale(s => { return { ...s, lang: strg.lang, locale: strg.lang === 'ar' ? ar_locale : en_locale, dir: strg.dir } });
            intl = createIntl({ locale: strg.lang, messages: strg.lang === 'ar' ? ar_locale : en_locale }, cache);
        }
        return () => setLocale(initialState);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        moment.locale(locale.lang === 'ar' ? ar : en);

        // set Doc title
        let docTitle = document.head.querySelector('title');
        docTitle.textContent = locale.locale["app.siteName"];

        // coreJsLocale(locale.lang);
        let antLtr = document.querySelector('link#ant-ltr');
        let customLtr = document.querySelector('link#custom-ltr');
        // let collectLtr = [antLtr, customLtr];
        let ltrLinks = [
            { id: 'ant-ltr', href: '/assets/css/antd.min.css', rel: 'stylesheet', node: antLtr },
            { id: 'custom-ltr', href: '/assets/css/custom.css', rel: 'stylesheet', node: customLtr }
        ];

        let bootstrapRtl = document.querySelector('link#bootstrap-rtl');
        let antRtl = document.querySelector('link#ant-rtl');
        let customRtl = document.querySelector('link#custom-rtl');
        // let collectRtl = [bootstrapRtl, antRtl, customRtl];
        let rtlLinks = [
            { id: 'bootstrap-rtl', href: '/assets/css/bootstrap-rtl.min.css', rel: 'stylesheet', node: bootstrapRtl },
            { id: 'ant-rtl', href: '/assets/css/antd-rtl.min.css', rel: 'stylesheet', node: antRtl },
            { id: 'custom-rtl', href: '/assets/css/custom-rtl.css', rel: 'stylesheet', node: customRtl }
        ];

        if (locale.lang === 'ar' || locale.lang === 'he') {
            // collectRtl.forEach(c => {
            //     if (c) c.style.display = 'block';
            // });
            // collectLtr.forEach(c => {
            //     if (c) c.style.display = 'none';
            // });
            // setLoading(true);
            // rtlLinks.forEach(l => {
            //     if (l && l.node) l.node.remove();
            // });

            rtlLinks.forEach(async (l, i, arr) => {

                if (l) {
                    await injectCSS(l.id, l.href);
                }
                if (i === arr.length - 1) {
                    let tt = setTimeout(() => { setLoading(false); clearTimeout(tt); }, 2000);
                }
            });

            ltrLinks.forEach(l => {
                if (l && l.node) l.node.remove();
            });

            // setCssLoaded(false);
            // require('bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css');
            // require('../shared/scss/antd-rtl.scss');
            // require('../scss/_custom-rtl.scss');
        }
        else {
            // collectRtl.forEach(c => {
            //     if (c) c.style.display = 'none';
            // });
            // collectLtr.forEach(c => {
            //     if (c) c.style.display = 'block';
            // });
            // setLoading(true);
            // ltrLinks.forEach(l => {
            //     if (l && l.node) l.node.remove();
            // });

            ltrLinks.forEach(async (l, i, arr) => {

                if (l) {
                    await injectCSS(l.id, l.href);
                }
                if (i === arr.length - 1) {
                    let tt = setTimeout(() => { setLoading(false); clearTimeout(tt); }, 2000);
                }
            });

            rtlLinks.forEach(l => {
                if (l && l.node) l.node.remove();
            });

            // setCssLoaded(true);
            // require('bootstrap/dist/css/bootstrap.min.css');
            // require('antd/dist/antd.min.css');
            // require('../scss/_custom.scss');
        }

        dom_RTL_LTR_Dir(locale.lang);

        // eslint-disable-next-line
    }, [locale.lang]);

    // useEffect(() => {
    //     if (cssLoaded) setLoading(false);
    // }, [cssLoaded]);

    const injectCSS = (id = '' + Math.random(), src = '') => {
        return new Promise((resolve, reject) => {
            if (!document) {
                resolve();
                return;
            }

            if (document.getElementById(id)) {
                resolve('CSS already loaded');
                return;
            }

            const link = document.createElement('link');
            link.id = id;
            link.setAttribute('rel', 'stylesheet');
            link.setAttribute('href', src);

            // link.addEventListener('load', () => resolve('CSS loaded'));

            link.addEventListener('error', () => reject('Error css'));
            link.addEventListener('abort', () => reject('CSS aborted'));
            document.head.appendChild(link);
            resolve('CSS loaded');
        });
    };


    const handleLocaleChange = (lng) => {
        setLoading(true);

        switch (lng) {
            case 'ar':
                // case 'he':
                setLocale({ ...locale, lang: lng, locale: ar_locale, dir: 'rtl' });
                dom_RTL_LTR_Dir(lng);
                localStorage.setItem("app.Intl_lang", JSON.stringify({ lang: lng, locale: ar_locale.locale, dir: 'rtl' }));
                intl = createIntl({ locale: lng, messages: ar_locale }, cache);
                break;
            case 'en':
                setLocale({ ...locale, lang: lng, locale: en_locale, dir: 'ltr' });
                dom_RTL_LTR_Dir(lng);
                localStorage.setItem("app.Intl_lang", JSON.stringify({ lang: lng, locale: en_locale.locale, dir: 'ltr' }));
                intl = createIntl({ locale: lng, messages: en_locale }, cache);

                break;

            default:
                //   console.log("def");
                setLocale({ ...locale, lang: 'ar', locale: ar_locale, dir: 'rtl' });
                dom_RTL_LTR_Dir('ar');
                localStorage.setItem("app.Intl_lang", JSON.stringify({ lang: lng, locale: ar_locale.locale, dir: 'rtl' }));
                intl = createIntl({ locale: lng, messages: ar_locale }, cache);
                break;
        }

        moment.locale(lng === 'ar' ? ar : en);
    };

    const dom_RTL_LTR_Dir = (lng, locale) => {
        if (!lng) return;

        let html = document.querySelector('html');
        let body = document.querySelector('body');
        let root = document.querySelector('#root');
        let selectors = [html, body, root];

        if (lng === 'ar' || lng === 'he') {
            selectors.forEach(s => {
                if (s.tagName.toLocaleLowerCase() === 'html') s.setAttribute('lang', (locale && locale.locale) || 'ar-EG');
                s.classList.add('rtl');
                s.classList.remove('ltr');
                s.setAttribute('dir', 'rtl');
            });
        }
        else {
            selectors.forEach(s => {
                if (s.tagName.toLocaleLowerCase() === 'html') s.setAttribute('lang', (locale && locale.locale) || 'en-US');
                s.classList.add('ltr');
                s.classList.remove('rtl');
                s.setAttribute('dir', 'ltr');
            });
        }
    };

    return (
        <I18n.Provider value={{ locale, handleLocaleChange, langLoading: loading }} >
            <IntlProvider key={locale.lang} locale={locale.lang} messages={locale.locale}>
                <ConfigProvider locale={locale.locale} direction={locale.locale.locale === 'ar' || locale.locale.locale === 'he' ? 'rtl' : 'ltr'} >
                    {
                        // (
                        //     loading &&
                        //     <div style={{ padding: 15 }}><Skeleton loading={loading} title={false} paragraph={{ rows: 15 }} active /></div>
                        // )
                        // ||
                        props.children
                    }
                </ConfigProvider>
            </IntlProvider>
        </I18n.Provider>
    )
}

export default IntlContextProvider;
