// export const LOADING = 'LOADING';

const ProviderVacationActionTypes = {};

ProviderVacationActionTypes.FETCH_ALLPROVIDERVACATIONS = 'FETCH_ALLPROVIDERVACATIONS';
ProviderVacationActionTypes.FETCH_ALLPROVIDERVACATIONSBYID = 'FETCH_ALLPROVIDERVACATIONSBYID';
ProviderVacationActionTypes.FETCH_PROVIDERVACATION = 'FETCH_PROVIDERVACATION';
ProviderVacationActionTypes.ADD_PROVIDERVACATION = 'ADD_PROVIDERVACATION';
ProviderVacationActionTypes.EDIT_PROVIDERVACATION = 'EDIT_PROVIDERVACATION';
ProviderVacationActionTypes.DELETE_PROVIDERVACATION = 'DELETE_PROVIDERVACATION';
ProviderVacationActionTypes.SEARCH_PROVIDERVACATION = 'SEARCH_PROVIDERVACATION';

export default ProviderVacationActionTypes;
