const ProgramActionTypes = {};

ProgramActionTypes.FETCH_ALL_PROGRAMS = 'FETCH_ALL_PROGRAMS';
ProgramActionTypes.FETCH_PROGRAM = 'FETCH_PROGRAM';
ProgramActionTypes.FETCH_PROGRAM_PAGE = 'FETCH_PROGRAM_PAGE';
ProgramActionTypes.FETCH_ENABLED_PROGRAM = 'FETCH_ENABLED_PROGRAM';
ProgramActionTypes.FETCH_DISABLED_PROGRAM = 'FETCH_DISABLED_PROGRAM';
ProgramActionTypes.ADD_PROGRAM = 'ADD_PROGRAM';
ProgramActionTypes.EDIT_PROGRAM = 'EDIT_PROGRAM';
ProgramActionTypes.UPDATE_PROGRAM = 'UPDATE_PROGRAM';
ProgramActionTypes.DELETE_PROGRAM = 'DELETE_PROGRAM';
ProgramActionTypes.SEARCH_PROGRAM = 'SEARCH_PROGRAM';
ProgramActionTypes.SEARCH_PROGRAM_BYPROVIDERID = 'SEARCH_PROGRAM_BYPROVIDERID';
ProgramActionTypes.ENABLE_PROGRAM = 'ENABLE_PROGRAM';
ProgramActionTypes.DISABLE_PROGRAM = 'DISABLE_PROGRAM';
ProgramActionTypes.ENABLE_SHOW_IN_HOME = 'ENABLE_SHOW_IN_HOME';
ProgramActionTypes.DISABLE_SHOW_IN_HOME = 'DISABLE_SHOW_IN_HOME';
ProgramActionTypes.ENABLE_SHOW_IN_SLIDER = 'ENABLE_SHOW_IN_SLIDER';
ProgramActionTypes.DISABLE_SHOW_IN_SLIDER = 'DISABLE_SHOW_IN_HSLIDER';
// ProgramActionTypes.PUBLISH_PROGRAM_COMMENT = 'PUBLISH_PROGRAM_COMMENT';
// ProgramActionTypes.UNPUBLISH_PROGRAM_COMMENT = 'UNPUBLISH_PROGRAM_COMMENT';

export default ProgramActionTypes;
