const ProviderDocumentActionTypes = {};

ProviderDocumentActionTypes.FETCH_PROVIDER_DOCUMENTS = 'FETCH_PROVIDER_DOCUMENTS';
ProviderDocumentActionTypes.FETCH_PROVIDER_DOCUMENT = 'FETCH_PROVIDER_DOCUMENT';
ProviderDocumentActionTypes.ADD_PROVIDER_DOCUMENT = 'ADD_PROVIDER_DOCUMENT';
ProviderDocumentActionTypes.EDIT_PROVIDER_DOCUMENT = 'EDIT_PROVIDER_DOCUMENT';
ProviderDocumentActionTypes.DELETE_PROVIDER_DOCUMENT = 'DELETE_PROVIDER_DOCUMENT';
ProviderDocumentActionTypes.FETCH_PROVIDER_DOCUMENT_BY_ID = 'FETCH_PROVIDER_DOCUMENT_BY_ID';


export default ProviderDocumentActionTypes;
