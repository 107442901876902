const ProgramTimeSlotActionTypes = {};

ProgramTimeSlotActionTypes.FETCH_ALL_PROGRAMTIMESLOTS = 'FETCH_ALL_PROGRAMTIMESLOTS';
ProgramTimeSlotActionTypes.FETCH_PROGRAMTIMESLOT = 'FETCH_PROGRAMTIMESLOT';
ProgramTimeSlotActionTypes.FETCH_PROGRAMTIMESLOTBYPROGRAMID = 'FETCH_PROGRAMTIMESLOTBYPROGRAMID';
ProgramTimeSlotActionTypes.FETCH_PROGRAMTIMESLOTFROMDATA = 'FETCH_PROGRAMTIMESLOTFROMDATA';
ProgramTimeSlotActionTypes.ADD_PROGRAMTIMESLOT = 'ADD_PROGRAMTIMESLOT';
ProgramTimeSlotActionTypes.EDIT_PROGRAMTIMESLOT = 'EDIT_PROGRAMTIMESLOT';
ProgramTimeSlotActionTypes.DELETE_PROGRAMTIMESLOT = 'DELETE_PROGRAMTIMESLOT';
ProgramTimeSlotActionTypes.COPY_PROGRAMTIMESLOT = 'COPY_PROGRAMTIMESLOT';
ProgramTimeSlotActionTypes.SEARCH_PROGRAMTIMESLOT = 'SEARCH_PROGRAMTIMESLOT';
ProgramTimeSlotActionTypes.TRUNCATE_PROGRAMTIMESLOT = 'TRUNCATE_PROGRAMTIMESLOT';

export default ProgramTimeSlotActionTypes;
