const ResourcesActionTypes = {};

ResourcesActionTypes.FETCH_RESOURCES = 'FETCH_RESOURCES';
ResourcesActionTypes.FETCH_RESOURCE = 'FETCH_RESOURCE';
ResourcesActionTypes.ADD_RESOURCE = 'ADD_RESOURCE';
ResourcesActionTypes.EDIT_RESOURCE = 'EDIT_RESOURCE';
ResourcesActionTypes.DELETE_RESOURCE = 'DELETE_RESOURCE';


export default ResourcesActionTypes;
