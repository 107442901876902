import ServiceOptionActionTypes from '../../Actions/ServiceOption/ActionTypes';

export const ServiceOptionReducer = (state = [], action) => {
    switch (action.type) {
        case ServiceOptionActionTypes.FETCH_ALLSERVICESOPTION:
            return action.payload;

        case ServiceOptionActionTypes.FETCH_SERVICEOPTION:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ServiceOptionActionTypes.FETCH_SERVICEOPTIONFROMDATA:
            return action.payload;

        case ServiceOptionActionTypes.ADD_SERVICEOPTION:
            return [...state, action.payload];

        case ServiceOptionActionTypes.EDIT_SERVICEOPTION:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ServiceOptionActionTypes.DELETE_SERVICEOPTION:
            return [...state.filter(item => item.id !== action.payload.id)];

        case ServiceOptionActionTypes.TRUNCATE_SERVICEOPTION:
            return action.payload.reducerDataTypeSymbol;

        default:
            return state;
    }
}