import CanceledReservationsActionTypes from '../../Actions/CanceledReservations/ActionTypes';

export const canceledReservationsReducer = (state = [], action) => {
    switch (action.type) {
        case CanceledReservationsActionTypes.FETCH_ALL_CANCELED_RESERVATIONS:
            return action.payload;
            
        case CanceledReservationsActionTypes.FETCH_CANCELED_RESERVATIONS:
            return state.map(item => item.id === action.payload.id ? action.payload : item); 
        
        case CanceledReservationsActionTypes.ADD_CANCELED_RESERVATIONS:
            return [...state, action.payload];

        case CanceledReservationsActionTypes.EDIT_CANCELED_RESERVATIONS:
            return state.map(item => item.id === action.payload.id ? action.payload : item);

        case CanceledReservationsActionTypes.DELETE_CANCELED_RESERVATIONS:
            return state.filter(item => item.id !== action.payload.id);
            
        default:
            return state;
    }
}