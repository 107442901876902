import ProgramPriceActionTypes from '../../Actions/ProgramPrice/ActionTypes';

export const programPriceReducer = (state = [], action) => {
    switch (action.type) {
        case ProgramPriceActionTypes.FETCH_ALL_PROGRAMSPRICE:
            return action.payload;

        case ProgramPriceActionTypes.FETCH_PROGRAMPRICE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ProgramPriceActionTypes.FETCH_PROGRAMPRICEFROMDATA:
            return action.payload;

        case ProgramPriceActionTypes.ADD_PROGRAMPRICE:
            return [...state, action.payload];

        case ProgramPriceActionTypes.EDIT_PROGRAMPRICE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProgramPriceActionTypes.DELETE_PROGRAMPRICE:
            return [...state.filter(item => item.id !== action.payload.id)];

        case ProgramPriceActionTypes.TRUNCATE_PROGRAMPRICE:
            return action.payload.reducerDataTypeSymbol;

        default:
            return state;
    }
}