import ResourcesActionTypes from '../../Actions/Resources/ActionTypes';

export const resourcesReducer = (state = [], action) => {
    switch (action.type) {
        case ResourcesActionTypes.FETCH_RESOURCES:
            return action.payload;
            
        case ResourcesActionTypes.FETCH_RESOURCE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload] 
            // return [...state, action.payload];
        
        case ResourcesActionTypes.ADD_RESOURCE:
            return [...state, action.payload];

        case ResourcesActionTypes.EDIT_RESOURCE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ResourcesActionTypes.DELETE_RESOURCE:
            return [...state.filter(item => item !== action.payload)];
            
        default:
            return state;
    }
}