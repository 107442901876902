// export const LOADING = 'LOADING';

const UserActionTypes = {};

UserActionTypes.FETCH_USERS = 'FETCH_USERS';
UserActionTypes.FETCH_USER = 'FETCH_USER';
UserActionTypes.ADD_USER = 'ADD_USER';
UserActionTypes.EDIT_USER = 'EDIT_USER';
UserActionTypes.DELETE_USER = 'DELETE_USER';
UserActionTypes.SET_STATUS = 'SET_STATUS';
UserActionTypes.SET_ROLE = 'SET_ROLE';
UserActionTypes.CHANGE_PASSWORD = 'CHANGE_PASSWORD';
UserActionTypes.GET_CURRENTUSER = 'GET_CURRENTUSER';
UserActionTypes.SET_CURRENTUSER_PERM = 'SET_CURRENTUSER_PERM';
UserActionTypes.GET_NOTMAPPEDPROVIDERS = 'GET_NOTMAPPEDPROVIDERS';

export default UserActionTypes;
