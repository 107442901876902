import ReservationActionTypes from '../../Actions/Reservation/ActionTypes';

export const ReservationReducer = (state = [], action) => {
    switch (action.type) {
        case ReservationActionTypes.FETCH_ALLRESERVATIONS:
            return action.payload;

        case ReservationActionTypes.FETCH_RESERVATION:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ReservationActionTypes.FETCH_RESERVATION_BYTIMESLOT:
            return action.payload && !action.payload.length ? 
            state :
            // [...state, ...action.payload];
            [...state.filter(item => item.timeSlotId !== action.payload[0].timeSlotId && action.payload.map(pl=>pl.id).indexOf(item.id) === -1), ...action.payload];
            // action.payload;

        case ReservationActionTypes.FETCH_RESERVATION_BYUSERID:
            return action.payload;

        case ReservationActionTypes.FETCH_RESERVATION_BYCURRENTUSER:
            return action.payload;

        case ReservationActionTypes.ADD_RESERVATION:
            return [...state, action.payload];

        case ReservationActionTypes.EDIT_RESERVATION:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ReservationActionTypes.DELETE_RESERVATION:
            return [...state.filter(item => item !== action.payload)];

        // case ReservationActionTypes.SEARCH_RESERVATION:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];

        default:
            return state;
    }
}