// export const LOADING = 'LOADING';

const PaymentActionTypes = {};

PaymentActionTypes.FETCH_ALLPAYMENT = 'FETCH_ALLPAYMENT';
PaymentActionTypes.FETCH_PAYMENT = 'FETCH_PAYMENT';
PaymentActionTypes.FETCH_PAYMENTFROMDATA = 'FETCH_PAYMENTFROMDATA';
PaymentActionTypes.ADD_PAYMENT = 'ADD_PAYMENT';
PaymentActionTypes.EDIT_PAYMENT = 'EDIT_PAYMENT';
PaymentActionTypes.DELETE_PAYMENT = 'DELETE_PAYMENT';
// PaymentActionTypes.SEARCH_PAYMENT = 'SEARCH_PAYMENT';

export default PaymentActionTypes;
