const LanguagesActionTypes = {};

LanguagesActionTypes.FETCH_LANGUAGES = 'FETCH_LANGUAGES';
LanguagesActionTypes.FETCH_LANGUAGE = 'FETCH_LANGUAGE';
LanguagesActionTypes.ADD_LANGUAGE = 'ADD_LANGUAGE';
LanguagesActionTypes.EDIT_LANGUAGE = 'EDIT_LANGUAGE';
LanguagesActionTypes.DELETE_LANGUAGE = 'DELETE_LANGUAGE';


export default LanguagesActionTypes;
