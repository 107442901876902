import CategoryActionTypes from '../../Actions/Category/ActionTypes';

export const CategoryReducer = (state = [], action) => {
    switch (action.type) {
        case CategoryActionTypes.FETCH_CATEGORIES:
            return action.payload;

        case CategoryActionTypes.FETCH_CATEGORY:
            return [...state.filter(item => item.id !== action.payload.id), action.payload]
        // return [...state, action.payload];

        case CategoryActionTypes.ADD_CATEGORY:
            return [...state, action.payload];

        case CategoryActionTypes.EDIT_CATEGORY:
            return state.map(item => item.id !== action.payload.id ? item : action.payload);

        case CategoryActionTypes.ENABLE_CATEGORY:
            return state.map(item => item.id !== action.payload.id ? item : action.payload);

        case CategoryActionTypes.DISABLE_CATEGORY:
            return state.map(item => item.id !== action.payload.id ? item : action.payload);

        case CategoryActionTypes.DELETE_CATEGORY:
            return [...state.filter(item => item !== action.payload)];

        default:
            return state;
    }
}