import ServiceTimeSlotActionTypes from '../../Actions/ServiceTimeSlot/ActionTypes';

export const ServiceTimeSlotReducer = (state = [], action) => {
    switch (action.type) {
        case ServiceTimeSlotActionTypes.FETCH_ALLSERVICETIMESLOTS:
            return action.payload;

        case ServiceTimeSlotActionTypes.FETCH_SERVICETIMESLOT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ServiceTimeSlotActionTypes.FETCH_SERVICETIMESLOTBYSERVICEID:
        return action.payload;

        case ServiceTimeSlotActionTypes.FETCH_SERVICETIMESLOTFROMDATA:
            return action.payload;

        case ServiceTimeSlotActionTypes.ADD_SERVICETIMESLOT:
            return [...state, action.payload];

        case ServiceTimeSlotActionTypes.EDIT_SERVICETIMESLOT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ServiceTimeSlotActionTypes.DELETE_SERVICETIMESLOT:
            return [...state.filter(item => item.id !== action.payload.id)];

        case ServiceTimeSlotActionTypes.COPY_SERVICETIMESLOT:
            return [...state, ...action.payload];

        case ServiceTimeSlotActionTypes.SEARCH_SERVICETIMESLOT:
            return action.payload;

        case ServiceTimeSlotActionTypes.TRUNCATE_SERVICETIMESLOT:
            return action.payload.reducerDataTypeSymbol;


        default:
            return state;
    }
}