import ProgramImageActionTypes from '../../Actions/ProgramImage/ActionTypes';

export const programImageReducer = (state = [], action) => {
    switch (action.type) {
        case ProgramImageActionTypes.FETCH_ALL_PROGRAMSIMAGE:
            return action.payload;

        case ProgramImageActionTypes.FETCH_PROGRAMIMAGE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProgramImageActionTypes.FETCH_PROGRAMIMAGE_FROMDATA:
            return action.payload;

        case ProgramImageActionTypes.ADD_PROGRAMIMAGE:
            return [...state, action.payload];

        case ProgramImageActionTypes.EDIT_PROGRAMIMAGE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProgramImageActionTypes.DELETE_PROGRAMIMAGE:
            return [...state.filter(item => item !== action.payload)];

        // case ProgramImageActionTypes.SEARCH_PROGRAMIMAGE:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProgramImageActionTypes.TRUNCATE_PROGRAMIMAGE:
            return action.payload.reducerDataTypeSymbol;

        default:
            return state;
    }
}