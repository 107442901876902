// export const LOADING = 'LOADING';

const ServiceTypesActionTypes = {};

ServiceTypesActionTypes.FETCH_TYPES = 'FETCH_TYPES';
ServiceTypesActionTypes.FETCH_TYPE = 'FETCH_TYPE';
ServiceTypesActionTypes.ADD_TYPE = 'ADD_TYPE';
ServiceTypesActionTypes.EDIT_TYPE = 'EDIT_TYPE';
ServiceTypesActionTypes.DELETE_TYPE = 'DELETE_TYPE';
ServiceTypesActionTypes.SEARCH_TYPE = 'SEARCH_TYPE';


export default ServiceTypesActionTypes;
