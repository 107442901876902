// export const LOADING = 'LOADING';

const OfferPriceActionTypes = {};

OfferPriceActionTypes.FETCH_ALL_PROGRAMSPRICE = 'FETCH_ALL_PROGRAMSPRICE';
OfferPriceActionTypes.FETCH_PROGRAMPRICE = 'FETCH_PROGRAMPRICE';
OfferPriceActionTypes.FETCH_PROGRAMPRICEFROMDATA = 'FETCH_PROGRAMPRICEFROMDATA';
OfferPriceActionTypes.ADD_PROGRAMPRICE = 'ADD_PROGRAMPRICE';
OfferPriceActionTypes.EDIT_PROGRAMPRICE = 'EDIT_PROGRAMPRICE';
OfferPriceActionTypes.DELETE_PROGRAMPRICE = 'DELETE_PROGRAMPRICE';
OfferPriceActionTypes.TRUNCATE_PROGRAMPRICE = 'TRUNCATE_PROGRAMPRICE';

export default OfferPriceActionTypes;
