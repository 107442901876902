import AuthenticateActionTypes from '../../Actions/Authenticate/ActionTypes';

export const AuthenticateReducer = (state = {}, action) => {
    switch (action.type) {
        case AuthenticateActionTypes.AUTHENTICATE:
            return action.payload;

        default:
            return state;
    }
}