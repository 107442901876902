// export const LOADING = 'LOADING';

const CategoryActionTypes = {};

CategoryActionTypes.FETCH_CATEGORIES = 'FETCH_CATEGORIES';
CategoryActionTypes.FETCH_CATEGORY = 'FETCH_CATEGORY';
CategoryActionTypes.ADD_CATEGORY = 'ADD_CATEGORY';
CategoryActionTypes.EDIT_CATEGORY = 'EDIT_CATEGORY';
CategoryActionTypes.DELETE_CATEGORY = 'DELETE_CATEGORY';
CategoryActionTypes.ENABLE_CATEGORY = 'ENABLE_CATEGORY';
CategoryActionTypes.DISABLE_CATEGORY = 'DISABLE_CATEGORY';
// CategoryActionTypes.SEARCH_CATEGORY = 'SEARCH_CATEGORY';


export default CategoryActionTypes;
