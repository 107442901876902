import API from '../Api';

//===========================================
//APIs

const PermissionApi = {};

PermissionApi.getAll = () => API.get('Permission');
PermissionApi.getByRole = (role) => API.get('Permission/Role/' + role);
PermissionApi.addRolePermission = (role, data) => API.post('Permission/Role/' + role, data);
PermissionApi.getByUser = (userId) => API.get('Permission/User/' + userId);
PermissionApi.addUserPermission = (userId, data) => API.post('Permission/User/' + userId, data);

export default PermissionApi;
