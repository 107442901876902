// export const LOADING = 'LOADING';

const ServiceActionTypes = {};

ServiceActionTypes.FETCH_ALLSERVICES = 'FETCH_ALLSERVICES';
ServiceActionTypes.FETCH_SERVICE = 'FETCH_SERVICE';
ServiceActionTypes.FETCH_SERVICE_PAGE = 'FETCH_SERVICE_PAGE';
ServiceActionTypes.FETCH_ENABLED_SERVICE = 'FETCH_ENABLED_SERVICE';
ServiceActionTypes.FETCH_DISABLED_SERVICE = 'FETCH_DISABLED_SERVICE';
ServiceActionTypes.ADD_SERVICE = 'ADD_SERVICE';
ServiceActionTypes.EDIT_SERVICE = 'EDIT_SERVICE';
ServiceActionTypes.UPDATE_SERVICE = 'UPDATE_SERVICE';
ServiceActionTypes.DELETE_SERVICE = 'DELETE_SERVICE';
ServiceActionTypes.SEARCH_SERVICE = 'SEARCH_SERVICE';
ServiceActionTypes.SEARCH_SERVICE_BYPROVIDERID = 'SEARCH_SERVICE_BYPROVIDERID';
ServiceActionTypes.ENABLE_SERVICE = 'ENABLE_SERVICE';
ServiceActionTypes.DISABLE_SERVICE = 'DISABLE_SERVICE';
ServiceActionTypes.ENABLE_SHOW_IN_HOME = 'ENABLE_SHOW_IN_HOME';
ServiceActionTypes.DISABLE_SHOW_IN_HOME = 'DISABLE_SHOW_IN_HOME';
ServiceActionTypes.ENABLE_SHOW_IN_SLIDER = 'ENABLE_SHOW_IN_SLIDER';
ServiceActionTypes.DISABLE_SHOW_IN_SLIDER = 'DISABLE_SHOW_IN_HSLIDER';
// ServiceActionTypes.PUBLISH_SERVICE_COMMENT = 'PUBLISH_SERVICE_COMMENT';
// ServiceActionTypes.UNPUBLISH_SERVICE_COMMENT = 'UNPUBLISH_SERVICE_COMMENT';

export default ServiceActionTypes;
