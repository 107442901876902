// export const LOADING = 'LOADING';

const ReservationActionTypes = {};

ReservationActionTypes.FETCH_ALLRESERVATIONS = 'FETCH_ALLRESERVATIONS';
ReservationActionTypes.FETCH_RESERVATION = 'FETCH_RESERVATION';
ReservationActionTypes.FETCH_RESERVATION_BYTIMESLOT = 'FETCH_RESERVATION_BYTIMESLOT';
ReservationActionTypes.FETCH_RESERVATION_BYUSERID = 'FETCH_RESERVATION_BYUSERID';
ReservationActionTypes.FETCH_RESERVATION_BYCURRENTUSER = 'FETCH_RESERVATION_BYCURRENTUSER';
ReservationActionTypes.ADD_RESERVATION = 'ADD_RESERVATION';
ReservationActionTypes.EDIT_RESERVATION = 'EDIT_RESERVATION';
ReservationActionTypes.DELETE_RESERVATION = 'DELETE_RESERVATION';
// ReservationActionTypes.SEARCH_RESERVATION = 'SEARCH_RESERVATION';

export default ReservationActionTypes;
