// export const LOADING = 'LOADING';

const CityActionTypes = {};

CityActionTypes.FETCH_CITIES = 'FETCH_CITIES';
CityActionTypes.FETCH_CITY = 'FETCH_CITY';
CityActionTypes.ADD_CITY = 'ADD_CITY';
CityActionTypes.EDIT_CITY = 'EDIT_CITY';
CityActionTypes.DELETE_CITY = 'DELETE_CITY';
CityActionTypes.ENABLE_CITY = 'ENABLE_CITY';
CityActionTypes.DISABLE_CITY = 'DISABLE_CITY';


export default CityActionTypes;
