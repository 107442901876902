import DynamicPagesActionTypes from '../../Actions/DynamicPages/ActionTypes';

export const dynamicPagesReducer = (state = [], action) => {
    switch (action.type) {
        case DynamicPagesActionTypes.FETCH_ALL_DYNAMIC_PAGES:
            return action.payload;

        case DynamicPagesActionTypes.FETCH_DYNAMIC_PAGES:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
            // return state.map(item => item.id === action.payload.id ? action.payload : item);

        case DynamicPagesActionTypes.ADD_DYNAMIC_PAGES:
            return [...state, action.payload];

        case DynamicPagesActionTypes.EDIT_DYNAMIC_PAGES:
            return state.map(item => item.id === action.payload.id ? action.payload : item);

        case DynamicPagesActionTypes.DELETE_DYNAMIC_PAGES:
            return state.filter(item => item.id !== action.payload.id);

        default:
            return state;
    }
}