const UserRoleActionTypes = {};

UserRoleActionTypes.FETCH_USER_ROLES = 'FETCH_USER_ROLES';
UserRoleActionTypes.FETCH_USER_ROLE = 'FETCH_USER_ROLE';
UserRoleActionTypes.ADD_USER_ROLE = 'ADD_USER_ROLE';
UserRoleActionTypes.EDIT_USER_ROLE = 'EDIT_USER_ROLE';
UserRoleActionTypes.DELETE_USER_ROLE = 'DELETE_USER_ROLE';


export default UserRoleActionTypes;
