import TermsAndConditionsActionTypes from '../../Actions/TermsAndConditions/ActionTypes';

export const termsAndConditionsReducer = (state = [], action) => {
    switch (action.type) {
        case TermsAndConditionsActionTypes.FETCH_ALL_TERMS_AND_CONDITIONS:
            return action.payload;
            
        case TermsAndConditionsActionTypes.FETCH_TERMS_AND_CONDITIONS:
            return state.map(item => item.id === action.payload.id ? action.payload : item); 
        
        case TermsAndConditionsActionTypes.ADD_TERMS_AND_CONDITIONS:
            return [...state, action.payload];

        case TermsAndConditionsActionTypes.EDIT_TERMS_AND_CONDITIONS:
            return state.map(item => item.id === action.payload.id ? action.payload : item);

        case TermsAndConditionsActionTypes.DELETE_TERMS_AND_CONDITIONS:
            return state.filter(item => item.id !== action.payload.id);
            
        default:
            return state;
    }
}