// export const LOADING = 'LOADING';

const ProviderActionTypes = {};

ProviderActionTypes.FETCH_PROVIDERS = 'FETCH_PROVIDERS';
ProviderActionTypes.FETCH_PROVIDER = 'FETCH_PROVIDER';
ProviderActionTypes.ENABLE_PROVIDER = 'ENABLE_PROVIDER';
ProviderActionTypes.DISABLE_PROVIDER = 'DISABLE_PROVIDER';
ProviderActionTypes.ADD_PROVIDER = 'ADD_PROVIDER';
ProviderActionTypes.EDIT_PROVIDER = 'EDIT_PROVIDER';
ProviderActionTypes.DELETE_PROVIDER = 'DELETE_PROVIDER';

export default ProviderActionTypes;
