import StatisticsActionTypes from '../../Actions/Statistics/ActionTypes';

export const statisticsReducer = (state = {}, action) => {
    switch (action.type) {
        case StatisticsActionTypes.FETCH_ALL_STATISTICS:
            return action.payload;
            
        // case StatisticsActionTypes.FETCH_STATISTICS:
        //     return state.map(item => item.id === action.payload.id ? action.payload : item); 
        
        // case StatisticsActionTypes.ADD_STATISTICS:
        //     return [...state, action.payload];

        // case StatisticsActionTypes.EDIT_STATISTICS:
        //     return state.map(item => item.id === action.payload.id ? action.payload : item);

        // case StatisticsActionTypes.DELETE_STATISTICS:
        //     return state.filter(item => item.id !== action.payload.id);
            
        default:
            return state;
    }
}