import React, { createContext, useState, useEffect } from 'react';
import Api, { axe } from "./shared/Api";

// const fs = require('fs');
// const path = require('path');

export const SettingsContext = createContext();

const AppSettingsProvider = props => {

    let settings = {
        REACT_APP_BASEURL: process.env.REACT_APP_BASEURL,
        REACT_APP_FILE_UPLOAD: process.env.REACT_APP_FILE_UPLOAD,
        REACT_APP_FILE_PATH: process.env.REACT_APP_FILE_PATH
    };
    // if (process.env.NODE_ENV === "development") {
    //     settings = require(__dirname + process.env.PUBLIC_URL + '/react.settings.json');
    // }
    // else if (process.env.NODE_ENV === "production") {
    //     settings = require(__dirname + process.env.PUBLIC_URL + '/react.settings.json');
    // }

    const [data, setData] = useState(settings);

    useEffect(() => {
        try {
            let fet = async () => {
                let getF = await axe.get(window.location.origin + '/react.settings.json');
                if (getF) {
                    setData(getF.data);
                    Api.defaults.baseURL = getF.data.REACT_APP_BASEURL || settings.REACT_APP_BASEURL;
                }
                // console.log("getF: ", getF);
            }
            fet();
        } catch (error) {
            console.log(error);
        }
        
        // eslint-disable-next-line
    }, []);

    // const { REACT_APP_BASEURL, REACT_APP_FILE_UPLOAD, REACT_APP_FILE_PATH } = settings;

    return (
        <SettingsContext.Provider value={{ ...data }}>
            {props.children}
        </SettingsContext.Provider>
    )
};

export default AppSettingsProvider;
