import ServiceRateActionTypes from '../../Actions/ServiceRate/ActionTypes';

export const serviceRateReducer = (state = [], action) => {
    switch (action.type) {
        // case ServiceRateActionTypes.FETCH_ALLSERVICERATES:
        //     return action.payload;

        // case ServiceRateActionTypes.FETCH_SERVICERATE:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // // return [...state, action.payload];

        case ServiceRateActionTypes.FETCH_SERVICERATEFROMDATA:
            return action.payload;

        // case ServiceRateActionTypes.ADD_SERVICERATE:
        //     return [...state, action.payload];

        // case ServiceRateActionTypes.EDIT_SERVICERATE:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];


        // case ServiceRateActionTypes.DELETE_SERVICERATE:
        //     return [...state.filter(item => item.id !== action.payload.id)];

        // case ServiceRateActionTypes.COPY_SERVICERATE:
        //     return [...state, action.payload];

        case ServiceRateActionTypes.PUBLISH_SERVICE_COMMENT:
            return [...state.map(item => item.id === action.payload.id ? action.payload : item)];

        case ServiceRateActionTypes.UNPUBLISH_SERVICE_COMMENT:
            return [...state.map(item => item.id === action.payload.id ? action.payload : item)];

        default:
            return state;
    }
}