// export const LOADING = 'LOADING';

const ServiceImageActionTypes = {};

ServiceImageActionTypes.FETCH_ALLSERVICESIMAGE = 'FETCH_ALLSERVICESIMAGE';
ServiceImageActionTypes.FETCH_SERVICEIMAGE = 'FETCH_SERVICEIMAGE';
ServiceImageActionTypes.FETCH_SERVICEIMAGE_FROMDATA = 'FETCH_SERVICEIMAGE_FROMDATA';
ServiceImageActionTypes.ADD_SERVICEIMAGE = 'ADD_SERVICEIMAGE';
ServiceImageActionTypes.EDIT_SERVICEIMAGE = 'EDIT_SERVICEIMAGE';
ServiceImageActionTypes.DELETE_SERVICEIMAGE = 'DELETE_SERVICEIMAGE';
ServiceImageActionTypes.TRUNCATE_SERVICEIMAGE = 'TRUNCATE_SERVICEIMAGE';

export default ServiceImageActionTypes;
