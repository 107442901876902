import SubscribeActionTypes from '../../Actions/Subscribe/ActionTypes';

export const subscribeReducer = (state = [], action) => {
    switch (action.type) {
        case SubscribeActionTypes.FETCH_ALL_SUBSCRIBE:
            return action.payload;
            
        case SubscribeActionTypes.FETCH_SUBSCRIBE:
            return state.map(item => item.id === action.payload.id ? action.payload : item); 
        
        case SubscribeActionTypes.ADD_SUBSCRIBE:
            return [...state, action.payload];

        case SubscribeActionTypes.EDIT_SUBSCRIBE:
            return state.map(item => item.id === action.payload.id ? action.payload : item);

        case SubscribeActionTypes.DELETE_SUBSCRIBE:
            return state.filter(item => item.id !== action.payload.id);
            
        default:
            return state;
    }
}