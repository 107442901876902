import ServiceImageActionTypes from '../../Actions/ServiceImage/ActionTypes';

export const ServiceImageReducer = (state = [], action) => {
    switch (action.type) {
        case ServiceImageActionTypes.FETCH_ALLSERVICESIMAGE:
            return action.payload;

        case ServiceImageActionTypes.FETCH_SERVICEIMAGE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ServiceImageActionTypes.FETCH_SERVICEIMAGE_FROMDATA:
            return action.payload;

        case ServiceImageActionTypes.ADD_SERVICEIMAGE:
            return [...state, action.payload];

        case ServiceImageActionTypes.EDIT_SERVICEIMAGE:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ServiceImageActionTypes.DELETE_SERVICEIMAGE:
            return [...state.filter(item => item !== action.payload)];

        // case ServiceImageActionTypes.SEARCH_SERVICEIMAGE:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ServiceImageActionTypes.TRUNCATE_SERVICEIMAGE:
            return action.payload.reducerDataTypeSymbol;

        default:
            return state;
    }
}