import ProviderVacationActionTypes from '../../Actions/ProviderVacation/ActionTypes';

export const ProviderVacationReducer = (state = [], action) => {
    switch (action.type) {
        case ProviderVacationActionTypes.FETCH_ALLPROVIDERVACATIONS:
            return action.payload;

        case ProviderVacationActionTypes.FETCH_ALLPROVIDERVACATIONSBYID:
            return action.payload;

        case ProviderVacationActionTypes.FETCH_PROVIDERVACATION:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ProviderVacationActionTypes.ADD_PROVIDERVACATION:
            return [...state, action.payload];

        case ProviderVacationActionTypes.EDIT_PROVIDERVACATION:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProviderVacationActionTypes.DELETE_PROVIDERVACATION:
            return [...state.filter(item => item !== action.payload)];

        // case ProviderVacationActionTypes.SEARCH_PROVIDERVACATION:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];

        default:
            return state;
    }
}