import ServiceAvailabilityActionTypes from '../../Actions/ServiceAvailability/ActionTypes';

export const ServiceAvailabilityReducer = (state = [], action) => {
    switch (action.type) {
        case ServiceAvailabilityActionTypes.FETCH_ALLSERVICESAVAILABILITY:
            return action.payload;

        case ServiceAvailabilityActionTypes.FETCH_SERVICEAVAILABILITY:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ServiceAvailabilityActionTypes.FETCH_SERVICEAVAILABILITYFROMDATA:
            return action.payload;

        case ServiceAvailabilityActionTypes.ADD_SERVICEAVAILABILITY:
            return [...state, action.payload];

        case ServiceAvailabilityActionTypes.EDIT_SERVICEAVAILABILITY:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ServiceAvailabilityActionTypes.DELETE_SERVICEAVAILABILITY:
            return [...state.filter(item => item.id !== action.payload.id)];

        default:
            return state;
    }
}