const TermsAndConditionsActionTypes = {};

TermsAndConditionsActionTypes.FETCH_ALL_TERMS_AND_CONDITIONS = 'FETCH_ALL_TERMS_AND_CONDITIONS';
TermsAndConditionsActionTypes.FETCH_TERMS_AND_CONDITIONS = 'FETCH_TERMS_AND_CONDITIONS';
TermsAndConditionsActionTypes.ADD_TERMS_AND_CONDITIONS = 'ADD_TERMS_AND_CONDITIONS';
TermsAndConditionsActionTypes.EDIT_TERMS_AND_CONDITIONS = 'EDIT_TERMS_AND_CONDITIONS';
TermsAndConditionsActionTypes.DELETE_TERMS_AND_CONDITIONS = 'DELETE_TERMS_AND_CONDITIONS';


export default TermsAndConditionsActionTypes;
