const RefundedReservationsActionTypes = {};

RefundedReservationsActionTypes.FETCH_ALL_REFUNDED_RESERVATIONS = 'FETCH_ALL_REFUNDED_RESERVATIONS';
RefundedReservationsActionTypes.FETCH_REFUNDED_RESERVATIONS = 'FETCH_REFUNDED_RESERVATIONS';
RefundedReservationsActionTypes.ADD_REFUNDED_RESERVATIONS = 'ADD_REFUNDED_RESERVATIONS';
RefundedReservationsActionTypes.ADD_NEW_REFUNDED_RESERVATIONS = 'ADD_NEW_REFUNDED_RESERVATIONS';
RefundedReservationsActionTypes.EDIT_REFUNDED_RESERVATIONS = 'EDIT_REFUNDED_RESERVATIONS';
RefundedReservationsActionTypes.DELETE_REFUNDED_RESERVATIONS = 'DELETE_REFUNDED_RESERVATIONS';

export default RefundedReservationsActionTypes;
