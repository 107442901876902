export default [
  "AccountMng.Register",
  "AccountMng.CreateRole",
  "AccountMng.DeleteRole",
  "AuditTrail.GetAudit",
  "AuditTrail.FilterAudit",
  "City.GetCity",
  "City.AddOrEdit",
  "City.Delete",
  "City.Enable",
  "City.Disable",
  "Contact.GetContact",
  "Contact.Edit",
  "DaynamicPages.Get",
  "DaynamicPages.Edit",
  "Image.GetImage",
  "Image.AddOrEdit",
  "Image.Delete",
  "Language.List",
  "Language.Add",
  "Language.Edit",
  "Language.Delete",
  "Location.GetLocation",
  "Location.AddOrEdit",
  "Location.Delete",
  "Location.Enable",
  "Location.Disable",
  "Payment.GetPayment",
  "Payment.GetList",
  "Payment.Add",
  "Payment.Edit",
  "Payment.Delete",
  "ProgramsServices.GetProgramService",
  "ProgramsServices.GetFullProgram",
  "ProgramsServices.AddOrEdit",
  "ProgramsServices.Delete",
  "ProgramsServices.Search",
  "Provider.GetProvider",
  "Provider.AddOrEdit",
  "Provider.Delete",
  "Provider.Enable",
  "Provider.Disable",
  "ProviderDash.GetServices",
  "ProviderDash.GetTimeSlots",
  "ProviderDash.GetReservation",
  "ProviderDash.ConfirmReservation",
  "ProviderDash.FinishTrip",
  "ProviderDash.GetServicesByDate",
  "ProviderDash.GetBalance",
  "Document.GetById",
  "Document.GetAll",
  "Document.GetByProvider",
  "Document.Add",
  "Document.Edit",
  "Document.Delete",
  "Vacation.Get",
  "Vacation.AddOrEdit",
  "Vacation.Delete",
  "Reservation.GetAvailabilityRevers",
  "Reservation.GetReservation",
  "Reservation.GetReservations",
  "Reservation.Add",
  "Reservation.Edit",
  "Reservation.Estimate",
  "Reservation.Delete",
  "Reservation.GetCncldReservation",
  "Reservation.GetRfndReservation",
  "Reservation.AddRefund",
  "ResOption.GetReservation",
  "ResOption.GetList",
  "ResOption.Add",
  "ResOption.Edit",
  "ResOption.Delete",
  "Resource.List",
  "Resource.Add",
  "Resource.Edit",
  "Resource.Delete",
  "Category.GetServiceCategory",
  "Category.AddOrEdit",
  "Category.Delete",
  "Category.Enable",
  "Category.Disable",
  "Option.GetServiceOption",
  "Option.AddOrEdit",
  "Option.Delete",
  "Price.GetServicePrice",
  "Price.AddOrEdit",
  "Price.Delete",
  "Services.GetServices",
  "Services.GetFullService",
  "Services.AddOrEdit",
  "Services.Delete",
  "Services.Search",
  "Services.Enable",
  "Services.Disable",
  "ServiceType.GetServiceType",
  "ServiceType.AddOrEdit",
  "ServiceType.Delete",
  "Subscribe.Get",
  "Subscribe.Add",
  "Subscribe.Delete",
  "Statistics.Get",
  "TermsAndConditions.Edit",
  "TermsAndConditions.Delete",
  "TermsAndConditions.Get",
  "Testimonial.Get",
  "Testimonial.AddOrEdit",
  "Testimonial.Delete",
  "TimeSlot.Get",
  "TimeSlot.GetByService",
  "TimeSlot.Copy",
  "TimeSlot.Add",
  "TimeSlot.Edit",
  "TimeSlot.Delete",
  "Trans.List",
  "Trans.ListByModel",
  "Trans.Add",
  "Trans.Edit",
  "Trans.Delete",
  "UserAccount.AddUser",
  "UserAccount.EditUser",
  "UserAccount.EditStatus",
  "UserAccount.EditRole",
  "UserAccount.GetUser",
  "UserAccount.GetNotMappedProviders",
  "UserAccount.CurrentUser",
  "UserAccount.GetUserByRole",
  "UserAccount.ChangePassword",
  "UserRole.List",
  "UserRole.Add",
  "UserRole.Edit",
  "UserRole.Delete",
  "RolePerm.List",
  "RolePerm.GetForUser",
  "RolePerm.SetForUser",
  "RolePerm.GetForRole",
  "RolePerm.SetForRole"
];