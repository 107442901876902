import ProgramOptionActionTypes from '../../Actions/ProgramOption/ActionTypes';

export const programOptionReducer = (state = [], action) => {
    switch (action.type) {
        case ProgramOptionActionTypes.FETCH_ALL_PROGRAMSOPTION:
            return action.payload;

        case ProgramOptionActionTypes.FETCH_PROGRAMOPTION:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ProgramOptionActionTypes.FETCH_PROGRAMOPTIONFROMDATA:
            return action.payload;

        case ProgramOptionActionTypes.ADD_PROGRAMOPTION:
            return [...state, action.payload];

        case ProgramOptionActionTypes.EDIT_PROGRAMOPTION:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProgramOptionActionTypes.DELETE_PROGRAMOPTION:
            return [...state.filter(item => item.id !== action.payload.id)];

        case ProgramOptionActionTypes.TRUNCATE_PROGRAMOPTION:
            return action.payload.reducerDataTypeSymbol;

        default:
            return state;
    }
}