const AuditActionTypes = {};

AuditActionTypes.FETCH_AUDITS = 'FETCH_AUDITS';
AuditActionTypes.FETCH_AUDIT = 'FETCH_AUDIT';
AuditActionTypes.SEARCH_AUDITS = 'SEARCH_AUDITS';
AuditActionTypes.ADD_AUDIT = 'ADD_AUDIT';
AuditActionTypes.EDIT_AUDIT = 'EDIT_AUDIT';
AuditActionTypes.DELETE_AUDIT = 'DELETE_AUDIT';


export default AuditActionTypes;
