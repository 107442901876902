import ProviderDashActionTypes from '../../Actions/ProviderDash/ActionTypes';

export const ProviderDashReducer = (state = { services: [], timeSlots: [], reservations: [], balance: [] }, action) => {
    switch (action.type) {
        case ProviderDashActionTypes.FETCH_PROVIDERDASH_SERVICES:
            return { ...state, services: action.payload };

        case ProviderDashActionTypes.FETCH_ALLPROVIDERDASH_RESERVATIONS:
            return { ...state, reservations: action.payload };

        case ProviderDashActionTypes.FETCH_PROVIDERDASH_SERVICESBYDATE:
            return { ...state, services: action.payload };

        case ProviderDashActionTypes.FETCH_PROVIDERDASH_BALANCE:
            return { ...state, balance: action.payload };

        case ProviderDashActionTypes.FETCH_PROVIDERDASH_RESERVATIONFROMDATA:
            return { ...state, reservations: [...state.reservations.filter(r => r.id !== action.payload.id), action.payload] };

        case ProviderDashActionTypes.FETCH_PROVIDERDASH_TIMESLOTFROMDATA:
            return { ...state, timeSlots: [...state.timeSlots.filter(r => r.id !== action.payload.id), ...action.payload] };

        case ProviderDashActionTypes.FETCH_PROVIDERDASH_TIMESLOTS:
            return { ...state, timeSlots: action.payload };

        case ProviderDashActionTypes.FETCH_PROVIDERDASH_RESERVATIONBYTIMESLOTID:
            return action.payload && !action.payload.length ?
                state :
                // [...state, ...action.payload];
                { ...state, reservations: [...state.reservations.filter(item => item.timeSlotId !== action.payload[0].timeSlotId && action.payload.map(pl => pl.id).indexOf(item.id) === -1), ...action.payload] };

        case ProviderDashActionTypes.PROVIDERDASH_CONFIRMRESERVATION:
            return { ...state, reservations: [...state.reservations.filter(r => r.id !== action.payload.id), action.payload] };

        case ProviderDashActionTypes.PROVIDERDASH_FINISHTRIP:
            return { ...state, reservations: [...state.reservations.filter(r => r.id !== action.payload.id), action.payload] };

        // case ProviderDashActionTypes.ADD_SERVICE:
        //     return [...state, action.payload];

        // case ProviderDashActionTypes.EDIT_SERVICE:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];

        // case ProviderDashActionTypes.DELETE_SERVICE:
        //     return [...state.filter(item => item !== action.payload)];

        // case ProviderDashActionTypes.SEARCH_SERVICE:
        //     return [...state.filter(item => item.id !== action.payload.id), action.payload];

        default:
            return state;
    }
}