import { combineReducers } from 'redux';

import { auditReducer } from './Audit/AuditReducer';
import { citiesReducer } from './Cities/CitiesReducer';
import { languagesReducer } from './Languages/LanguagesReducer';
import { resourcesReducer } from './Resources/ResourcesReducer';
import { TypesReducer } from './Types/TypesReducer';
import { CategoryReducer } from './Category/CategoryReducer';
import { ProviderReducer } from './Provider/ProviderReducer';
import { ProviderVacationReducer } from './ProviderVacation/ProviderVacationReducer';
import { LocationReducer } from './Location/LocationReducer';
import { ServiceAvailabilityReducer } from './ServiceAvailability/ServiceAvailabilityReducer';
import { ServiceOptionReducer } from './ServiceOption/ServiceOptionReducer';
import { ServicePriceReducer } from './ServicePrice/ServicePriceReducer';
import { ServiceTimeSlotReducer } from './ServiceTimeSlot/ServiceTimeSlotReducer';
import { serviceReducer } from './Service/ServiceReducer';
import { ServiceImageReducer } from './ServiceImage/ServiceImageReducer';
import { serviceRateReducer } from './ServiceRate/ServiceRateReducer';
import { ReservationReducer } from './Reservation/ReservationReducer';
import { ReservationOptionReducer } from './ReservationOption/ReservationOptionReducer';
import { canceledReservationsReducer } from './CanceledReservations/CanceledReservationsReducer';
import { refundedReservationsReducer } from './RefundedReservations/RefundedReservationsReducer';
import { PaymentReducer } from './Payment/PaymentReducer';
import { UserReducer } from './User/UserReducer';
import { AuthenticateReducer } from './Authenticate/AuthenticateReducer';
import { ProviderDashReducer } from './ProviderDash/ProviderDashReducer';
import { providerDocumentReducer } from './ProviderDocument/ProviderDocumentReducer';
import { userRoleReducer } from './UserRole/UserRoleReducer';
import { permissionReducer } from './Permission/PermissionReducer';
import { termsAndConditionsReducer } from './TermsAndConditions/TermsAndConditionsReducer';
import { subscribeReducer } from './Subscribe/SubscribeReducer';
import { contactReducer } from './Contact/ContactReducer';
import { dynamicPagesReducer } from './DynamicPages/DynamicPagesReducer';
import { programReducer } from './Program/ProgramReducer';
import { programImageReducer } from './ProgramImage/ProgramImageReducer';
import { programOptionReducer } from './ProgramOption/ProgramOptionReducer';
import { programPriceReducer } from './ProgramPrice/ProgramPriceReducer';
import { programTimeSlotReducer } from './ProgramTimeSlot/ProgramTimeSlotReducer';
import { programRateReducer } from './ProgramRate/ProgramRateReducer';
import { testimonialsReducer } from './Testimonials/TestimonialsReducer';
import { statisticsReducer } from './Statistics/StatisticsReducer';

const appReducer = combineReducers({
    audits: auditReducer,
    cities: citiesReducer,
    languages: languagesReducer,
    resources: resourcesReducer,
    types: TypesReducer,
    providers: ProviderReducer,
    providerVacations: ProviderVacationReducer,
    categories: CategoryReducer,
    locations: LocationReducer,
    serviceAvailability: ServiceAvailabilityReducer,
    serviceOption: ServiceOptionReducer,
    servicePrice: ServicePriceReducer,
    serviceTimeSlot: ServiceTimeSlotReducer,
    services: serviceReducer,
    serviceImage: ServiceImageReducer,
    serviceRate: serviceRateReducer,
    reservations: ReservationReducer,
    reservationOption: ReservationOptionReducer,
    canceledReservations: canceledReservationsReducer,
    refundedReservations: refundedReservationsReducer,
    payments: PaymentReducer,
    users: UserReducer,
    Authenticate: AuthenticateReducer,
    providerDash: ProviderDashReducer,
    providerDocuments: providerDocumentReducer,
    userRoles: userRoleReducer,
    permissions: permissionReducer,
    termsAndConditions: termsAndConditionsReducer,
    subscribe: subscribeReducer,
    contact: contactReducer,
    dynamicPages: dynamicPagesReducer,
    programs: programReducer,
    programImage: programImageReducer,
    programOption: programOptionReducer,
    programPrice: programPriceReducer,
    programTimeSlot: programTimeSlotReducer,
    programRate: programRateReducer,
    testimonials: testimonialsReducer,
    statistics: statisticsReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
        state = action.payload || {};
    }

    return appReducer(state, action);
}

export default rootReducer;