// export const LOADING = 'LOADING';

const ServiceRateActionTypes = {};

// ServiceRateActionTypes.FETCH_ALLSERVICERATES = 'FETCH_ALLSERVICERATES';
// ServiceRateActionTypes.FETCH_SERVICERATE = 'FETCH_SERVICERATE';
// ServiceRateActionTypes.FETCH_SERVICERATEBYSERVICEID = 'FETCH_SERVICERATEBYSERVICEID';
ServiceRateActionTypes.FETCH_SERVICERATEFROMDATA = 'FETCH_SERVICERATEFROMDATA';
// ServiceRateActionTypes.ADD_SERVICERATE = 'ADD_SERVICERATE';
// ServiceRateActionTypes.EDIT_SERVICERATE = 'EDIT_SERVICERATE';
// ServiceRateActionTypes.DELETE_SERVICERATE = 'DELETE_SERVICERATE';
// ServiceRateActionTypes.COPY_SERVICERATE = 'COPY_SERVICERATE';
ServiceRateActionTypes.PUBLISH_SERVICE_COMMENT = 'PUBLISH_SERVICE_COMMENT';
ServiceRateActionTypes.UNPUBLISH_SERVICE_COMMENT = 'UNPUBLISH_SERVICE_COMMENT';

export default ServiceRateActionTypes;
