import ProgramTimeSlotActionTypes from '../../Actions/ProgramTimeSlot/ActionTypes';

export const programTimeSlotReducer = (state = [], action) => {
    switch (action.type) {
        case ProgramTimeSlotActionTypes.FETCH_ALL_PROGRAMTIMESLOTS:
            return action.payload;

        case ProgramTimeSlotActionTypes.FETCH_PROGRAMTIMESLOT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
        // return [...state, action.payload];

        case ProgramTimeSlotActionTypes.FETCH_PROGRAMTIMESLOTBYPROGRAMID:
        return action.payload;

        case ProgramTimeSlotActionTypes.FETCH_PROGRAMTIMESLOTFROMDATA:
            return action.payload;

        case ProgramTimeSlotActionTypes.ADD_PROGRAMTIMESLOT:
            return [...state, action.payload];

        case ProgramTimeSlotActionTypes.EDIT_PROGRAMTIMESLOT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case ProgramTimeSlotActionTypes.DELETE_PROGRAMTIMESLOT:
            return [...state.filter(item => item.id !== action.payload.id)];

        case ProgramTimeSlotActionTypes.COPY_PROGRAMTIMESLOT:
            return [...state, ...action.payload];

        case ProgramTimeSlotActionTypes.SEARCH_PROGRAMTIMESLOT:
            return action.payload;

        case ProgramTimeSlotActionTypes.TRUNCATE_PROGRAMTIMESLOT:
            return action.payload.reducerDataTypeSymbol;


        default:
            return state;
    }
}