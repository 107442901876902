// export const LOADING = 'LOADING';

const ProgramImageActionTypes = {};

ProgramImageActionTypes.FETCH_ALL_PROGRAMSIMAGE = 'FETCH_ALL_PROGRAMSIMAGE';
ProgramImageActionTypes.FETCH_PROGRAMIMAGE = 'FETCH_PROGRAMIMAGE';
ProgramImageActionTypes.FETCH_PROGRAMIMAGE_FROMDATA = 'FETCH_PROGRAMIMAGE_FROMDATA';
ProgramImageActionTypes.ADD_PROGRAMIMAGE = 'ADD_PROGRAMIMAGE';
ProgramImageActionTypes.EDIT_PROGRAMIMAGE = 'EDIT_PROGRAMIMAGE';
ProgramImageActionTypes.DELETE_PROGRAMIMAGE = 'DELETE_PROGRAMIMAGE';
ProgramImageActionTypes.TRUNCATE_PROGRAMIMAGE = 'TRUNCATE_PROGRAMIMAGE';

export default ProgramImageActionTypes;
