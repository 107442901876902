import AuditActionTypes from '../../Actions/Audit/ActionTypes';

export const auditReducer = (state = [], action) => {
    switch (action.type) {
        case AuditActionTypes.FETCH_AUDITS:
            return action.payload;

        case AuditActionTypes.FETCH_AUDIT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload]
        // return [...state, action.payload];

        case AuditActionTypes.SEARCH_AUDITS:
            // return [...state.filter(item => item.id !== action.payload.id), action.payload]
            return action.payload.data;

        case AuditActionTypes.ADD_AUDIT:
            return [...state, action.payload];

        case AuditActionTypes.EDIT_AUDIT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case AuditActionTypes.DELETE_AUDIT:
            return [...state.filter(item => item !== action.payload)];

        default:
            return state;
    }
}