// export const LOADING = 'LOADING';

const ServiceOptionActionTypes = {};

ServiceOptionActionTypes.FETCH_ALLSERVICESOPTION = 'FETCH_ALLSERVICESOPTION';
ServiceOptionActionTypes.FETCH_SERVICEOPTION = 'FETCH_SERVICEOPTION';
ServiceOptionActionTypes.FETCH_SERVICEOPTIONFROMDATA = 'FETCH_SERVICEOPTIONFROMDATA';
ServiceOptionActionTypes.ADD_SERVICEOPTION = 'ADD_SERVICEOPTION';
ServiceOptionActionTypes.EDIT_SERVICEOPTION = 'EDIT_SERVICEOPTION';
ServiceOptionActionTypes.DELETE_SERVICEOPTION = 'DELETE_SERVICEOPTION';
ServiceOptionActionTypes.TRUNCATE_SERVICEOPTION = 'TRUNCATE_SERVICEOPTION';

export default ServiceOptionActionTypes;
