import RefundedReservationsActionTypes from '../../Actions/RefundedReservations/ActionTypes';

export const refundedReservationsReducer = (state = [], action) => {
    switch (action.type) {
        case RefundedReservationsActionTypes.FETCH_ALL_REFUNDED_RESERVATIONS:
            return action.payload;

        case RefundedReservationsActionTypes.FETCH_REFUNDED_RESERVATIONS:
            return state.map(item => item.id === action.payload.id ? action.payload : item);

        case RefundedReservationsActionTypes.ADD_REFUNDED_RESERVATIONS:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];
            // return [...state, action.payload];

        case RefundedReservationsActionTypes.ADD_NEW_REFUNDED_RESERVATIONS:
            return state.filter(item => item.id !== action.payload.id);
        // return [...state, action.payload];

        case RefundedReservationsActionTypes.EDIT_REFUNDED_RESERVATIONS:
            return state.map(item => item.id === action.payload.id ? action.payload : item);

        case RefundedReservationsActionTypes.DELETE_REFUNDED_RESERVATIONS:
            return state.filter(item => item.id !== action.payload.id);

        default:
            return state;
    }
}