// export const LOADING = 'LOADING';

const ReservationOptionActionTypes = {};

ReservationOptionActionTypes.FETCH_ALLRESERVATIONOPTION = 'FETCH_ALLRESERVATIONOPTION';
ReservationOptionActionTypes.FETCH_RESERVATIONOPTION = 'FETCH_RESERVATIONOPTION';
ReservationOptionActionTypes.FETCH_RESERVATIONOPTIONFROMDATA = 'FETCH_RESERVATIONOPTIONFROMDATA';
ReservationOptionActionTypes.ADD_RESERVATIONOPTION = 'ADD_RESERVATIONOPTION';
ReservationOptionActionTypes.EDIT_RESERVATIONOPTION = 'EDIT_RESERVATIONOPTION';
ReservationOptionActionTypes.DELETE_RESERVATIONOPTION = 'DELETE_RESERVATIONOPTION';
// ReservationOptionActionTypes.SEARCH_RESERVATIONOPTION = 'SEARCH_ReservationOption';

export default ReservationOptionActionTypes;
