// export const LOADING = 'LOADING';

const ProviderDashActionTypes = {};

ProviderDashActionTypes.FETCH_PROVIDERDASH_SERVICES = 'FETCH_PROVIDERDASH_SERVICES';
ProviderDashActionTypes.FETCH_ALLPROVIDERDASH_RESERVATIONS = 'FETCH_ALLPROVIDERDASH_RESERVATIONS';
ProviderDashActionTypes.FETCH_PROVIDERDASH_SERVICESBYDATE = 'FETCH_PROVIDERDASH_SERVICESBYDATE';
ProviderDashActionTypes.FETCH_PROVIDERDASH_BALANCE = 'FETCH_PROVIDERDASH_BALANCE';
ProviderDashActionTypes.FETCH_PROVIDERDASH_RESERVATIONFROMDATA = 'FETCH_PROVIDERDASH_RESERVATIONFROMDATA';
ProviderDashActionTypes.FETCH_PROVIDERDASH_TIMESLOTFROMDATA = 'FETCH_PROVIDERDASH_TIMESLOTFROMDATA';
ProviderDashActionTypes.FETCH_PROVIDERDASH_RESERVATIONBYTIMESLOTID = 'FETCH_PROVIDERDASH_RESERVATIONBYTIMESLOTID';
ProviderDashActionTypes.FETCH_PROVIDERDASH_TIMESLOTS = 'FETCH_PROVIDERDASH_TIMESLOTS';
ProviderDashActionTypes.PROVIDERDASH_CONFIRMRESERVATION = 'PROVIDERDASH_CONFIRMRESERVATION';
ProviderDashActionTypes.PROVIDERDASH_FINISHTRIP = 'PROVIDERDASH_FINISHTRIP';
// ProviderDashActionTypes.ADD_PROVIDERDASH = 'ADD_PROVIDERDASH';
// ProviderDashActionTypes.EDIT_PROVIDERDASH = 'EDIT_PROVIDERDASH';
// ProviderDashActionTypes.DELETE_PROVIDERDASH = 'DELETE_PROVIDERDASH';
// // ProviderDashActionTypes.SET_STATUS = 'SET_STATUS';
// ProviderDashActionTypes.SET_ROLE = 'SET_ROLE';

export default ProviderDashActionTypes;
