import ProgramActionTypes from '../../Actions/Program/ActionTypes';

export const programReducer = (state = { all: [], active: [], notActive: [] }, action) => {
    switch (action.type) {
        case ProgramActionTypes.FETCH_ALL_PROGRAMS:
            return {
                ...state,
                all: action.payload,
                active: action.payload.filter(s => !!s.isActive),
                notActive: action.payload.filter(s => !s.isActive)
            };

        case ProgramActionTypes.FETCH_ENABLED_PROGRAM:
            return {
                ...state,
                all: [...state.all.filter(item => action.payload.map(pl => pl.id).indexOf(item.id) < 0), ...action.payload],
                active: action.payload.length && action.payload.filter(s => !!s.isActive).length ? action.payload.filter(s => s.isActive === true) : state.active,
                notActive: action.payload.length && action.payload.filter(s => !s.isActive).length ? action.payload.filter(s => !s.isActive) : state.notActive,
            };

        case ProgramActionTypes.FETCH_DISABLED_PROGRAM:
            return {
                ...state,
                all: [...state.all.filter(item => action.payload.map(pl => pl.id).indexOf(item.id) < 0), ...action.payload],
                active: action.payload.length && action.payload.filter(s => !!s.isActive).length ? action.payload.filter(s => s.isActive === true) : state.active,
                notActive: action.payload.length && action.payload.filter(s => !s.isActive).length ? action.payload.filter(s => !s.isActive) : state.notActive,
            };

        case ProgramActionTypes.FETCH_PROGRAM_PAGE:
            return {
                ...state,
                all: action.payload.data.length ?
                    [
                        ...new Set([
                            ...state.all.filter(s => action.payload.data.map(it => it.id).length && action.payload.data.map(it => it.id).indexOf(s.id) < 0),
                            ...action.payload.data
                        ])
                    ] : state.all,
                active: action.payload.data.length ?
                    [
                        ...new Set([
                            ...state.active.filter(s => action.payload.data.map(it => it.id).length && action.payload.data.map(it => it.id).indexOf(s.id) < 0),
                            ...action.payload.data.filter(item => !!item.isActive)
                        ])
                    ] : state.active,
                notActive: action.payload.data.length ?
                    [
                        ...new Set([
                            ...state.notActive.filter(s => action.payload.data.map(it => it.id).length && action.payload.data.map(it => it.id).indexOf(s.id) < 0),
                            ...action.payload.data.filter(item => !item.isActive)
                        ])
                    ] : state.notActive
            };
        // return {
        //     ...state,
        //     all: action.payload.data.length ? [...new Set([...state.all, ...action.payload.data])] : state.all,
        //     active: action.payload.data.length ? [...new Set([...state.active, ...action.payload.data.filter(item => !!item.isActive)])] : state.active,
        //     notActive: action.payload.data.length ? [...new Set([...state.notActive, ...action.payload.data.filter(item => !item.isActive)])] : state.notActive
        // };

        case ProgramActionTypes.FETCH_PROGRAM:
            return {
                ...state,
                all: [...state.all.filter(item => item.id !== action.payload.id), action.payload],
                active: action.payload && action.payload.isActive ? [...state.active.filter(item => item.id !== action.payload.id), action.payload] : state.active,
                notActive: action.payload && !action.payload.isActive ? [...state.notActive.filter(item => item.id !== action.payload.id), action.payload] : state.notActive
            };

        case ProgramActionTypes.ADD_PROGRAM:
            return {
                ...state,
                all: [...state.all, action.payload],
                notActive: [...state.notActive, action.payload]
            };

        case ProgramActionTypes.EDIT_PROGRAM:
            return {
                ...state,
                all: [
                    ...state.all.filter(item => item.id !== action.payload.id),
                    { ...state.all.filter(item => item.id === action.payload.id)[0], ...action.payload }
                ],
                active: action.payload && action.payload.isActive ?
                    [
                        ...state.active.filter(item => item.id !== action.payload.id),
                        { ...state.active.filter(item => item.id === action.payload.id)[0], ...action.payload }
                    ] :
                    state.active,
                notActive: action.payload && !action.payload.isActive ?
                    [
                        ...state.notActive.filter(item => item.id !== action.payload.id),
                        { ...state.notActive.filter(item => item.id === action.payload.id)[0], ...action.payload }
                    ] :
                    state.notActive
            };

        case ProgramActionTypes.UPDATE_PROGRAM:
            return {
                ...state,
                all: [
                    ...state.all.filter(item => item.id !== action.payload.programsId),
                    {
                        ...state.all.filter(item => item.id === action.payload.programsId)[0][action.payload.property].filter(t => t.id !== action.payload.id)[0],
                        ...action.payload.data
                    }
                ],
                active: action.payload && action.payload.isActive ?
                    [
                        ...state.active.filter(item => item.id !== action.payload.programsId),
                        { ...state.active.filter(item => item.id === action.payload.programsId)[0], ...action.payload }
                    ] :
                    state.active,
                notActive: action.payload && !action.payload.isActive ?
                    [
                        ...state.notActive.filter(item => item.id !== action.payload.programsId),
                        { ...state.notActive.filter(item => item.id === action.payload.programsId)[0], ...action.payload }
                    ] :
                    state.notActive
            };

        case ProgramActionTypes.DELETE_PROGRAM:
            return {
                ...state,
                all: [...state.all.filter(item => item !== action.payload)],
                active: [...state.active.filter(item => item !== action.payload)],
                notActive: [...state.notActive.filter(item => item !== action.payload)]
            };

        case ProgramActionTypes.SEARCH_PROGRAM:
            return {
                ...state,
                all: [
                    ...state.all.filter(item =>
                        Object.entries(item).map(val =>
                            !val.includes(Object.entries(action.payload).map(b => b[0])[0]) &&
                            !val.includes(Object.entries(action.payload).map(b => b[1])[0]))[0]
                    ),
                    { ...action.payload }
                ]
            };

        case ProgramActionTypes.SEARCH_PROGRAM_BYPROVIDERID:
            return {
                ...state,
                all: action.payload.length ?
                    [...state.all.filter(item => item.providerId !== action.payload[0].providerId), ...action.payload] :
                    state.all,
            };

        case ProgramActionTypes.ENABLE_PROGRAM:
            return {
                ...state,
                all: [...state.all.filter(item => item.id !== action.payload.id), action.payload],
                notActive: [...state.notActive.filter(item => item.id !== action.payload.id)],
                active: [...state.active, action.payload]
            };

        case ProgramActionTypes.DISABLE_PROGRAM:
            return {
                ...state,
                all: [...state.all.filter(item => item.id !== action.payload.id), action.payload],
                active: [...state.active.filter(item => item.id !== action.payload.id)],
                notActive: [...state.notActive, action.payload]
            };

        case ProgramActionTypes.ENABLE_SHOW_IN_HOME:
            return {
                ...state,
                all: [...state.all.map(item => item.id === action.payload.id ? action.payload : item)],
                notActive: [...state.notActive.map(item => item.id === action.payload.id ? action.payload : item)],
                active: [...state.active.map(item => item.id === action.payload.id ? action.payload : item)]
            };

        case ProgramActionTypes.DISABLE_SHOW_IN_HOME:
            return {
                ...state,
                all: [...state.all.map(item => item.id === action.payload.id ? action.payload : item)],
                active: [...state.active.map(item => item.id === action.payload.id ? action.payload : item)],
                notActive: [...state.notActive.map(item => item.id === action.payload.id ? action.payload : item)]
            };

        case ProgramActionTypes.ENABLE_SHOW_IN_SLIDER:
            return {
                ...state,
                all: [...state.all.map(item => item.id === action.payload.id ? action.payload : item)],
                notActive: [...state.notActive.map(item => item.id === action.payload.id ? action.payload : item)],
                active: [...state.active.map(item => item.id === action.payload.id ? action.payload : item)]
            };

        case ProgramActionTypes.DISABLE_SHOW_IN_SLIDER:
            return {
                ...state,
                all: [...state.all.map(item => item.id === action.payload.id ? action.payload : item)],
                active: [...state.active.map(item => item.id === action.payload.id ? action.payload : item)],
                notActive: [...state.notActive.map(item => item.id === action.payload.id ? action.payload : item)]
            };

        default:
            return state;
    }
}