// export const LOADING = 'LOADING';

const ServiceTimeSlotActionTypes = {};

ServiceTimeSlotActionTypes.FETCH_ALLSERVICETIMESLOTS = 'FETCH_ALLSERVICETIMESLOTS';
ServiceTimeSlotActionTypes.FETCH_SERVICETIMESLOT = 'FETCH_SERVICETIMESLOT';
ServiceTimeSlotActionTypes.FETCH_SERVICETIMESLOTBYSERVICEID = 'FETCH_SERVICETIMESLOTBYSERVICEID';
ServiceTimeSlotActionTypes.FETCH_SERVICETIMESLOTFROMDATA = 'FETCH_SERVICETIMESLOTFROMDATA';
ServiceTimeSlotActionTypes.ADD_SERVICETIMESLOT = 'ADD_SERVICETIMESLOT';
ServiceTimeSlotActionTypes.EDIT_SERVICETIMESLOT = 'EDIT_SERVICETIMESLOT';
ServiceTimeSlotActionTypes.DELETE_SERVICETIMESLOT = 'DELETE_SERVICETIMESLOT';
ServiceTimeSlotActionTypes.COPY_SERVICETIMESLOT = 'COPY_SERVICETIMESLOT';
ServiceTimeSlotActionTypes.SEARCH_SERVICETIMESLOT = 'SEARCH_SERVICETIMESLOT';
ServiceTimeSlotActionTypes.TRUNCATE_SERVICETIMESLOT = 'TRUNCATE_SERVICETIMESLOT';

export default ServiceTimeSlotActionTypes;
